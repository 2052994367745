<template>
    <modal-base-layout title="Guest Verification">
        <ion-card>
            <ion-card-header>
                <ion-label class="section-title" v-if="store.user.is_verification_enabled"
                    >Status: {{ store.user.is_verified ? 'Verified' : 'Unverified' }}</ion-label
                >
                <ion-label class="section-title" v-else>Guest Verification Not Available</ion-label>
            </ion-card-header>
            <ion-card-content>
                <ion-list lines="none" v-if="store.user.is_verification_enabled">
                    <div style="margin: 10px 15px" v-if="!store.user.is_verified">
                        <ion-label
                            >A valid photo ID is required for all bookings. Please upload a valid photo ID using the
                            start verification button below.</ion-label
                        >
                        <div style="width: 100%; text-align: center; margin-top: 15px">
                            <ion-button @click="openLink()">Start Verification</ion-button>
                        </div>
                    </div>
                    <ion-item v-else>
                        <ion-label>No further action is required.</ion-label>
                    </ion-item>
                </ion-list>
                <ion-list lines="none" v-else>
                    <ion-item>
                        <ion-label>This property manager does not require guest verification.</ion-label>
                    </ion-item>
                </ion-list>
            </ion-card-content>
        </ion-card>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, ref } from 'vue';
    import { bicycle, closeOutline, home, restaurant, trailSign, chevronForward } from 'ionicons/icons';
    import { useStore } from '@/store/store';
    import AppAPI from '@/services/AppAPI';
    import AppFunctions from '@/services/AppFunctions';

    export default defineComponent({
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();
            const url = ref('');

            onMounted(async () => {
                const data = (await AppAPI.getGuestVerificationLink()).data;
                url.value = data.url;
            });

            const openLink = async () => {
                await AppFunctions.openLink(url.value);
            };

            return {
                ionRouter,
                closeOutline,
                store,
                bicycle,
                chevronForward,
                restaurant,
                trailSign,
                home,
                openLink,
            };
        },
    });
</script>

<style scoped></style>
