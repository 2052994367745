import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"margin":"10px"} }
const _hoisted_2 = { style: {"display":"flex","justify-content":"space-between","align-items":"center"} }
const _hoisted_3 = { style: {"text-align":"left","margin-top":"15px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, { title: "Notification Details" }, {
    footer: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    style: {"width":"-webkit-fill-available","--border-radius":"10px"},
                    color: "primary",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Back")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ion_label, {
            style: {"font-size":"22px","font-weight":"800","color":"black","align-items":"center","text-align":"left"},
            "text-wrap": ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.item.title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_label, { style: {"text-align":"right"} }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatDateWithTime(_ctx.item.created)), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ion_label, {
            style: {"font-size":"medium"},
            "text-wrap": ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.item.message), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}