import { toastController } from '@ionic/vue';
import { alertCircleOutline, checkmarkCircleOutline } from 'ionicons/icons';

async function successToast(message: string) {
    const toast = await toastController.create({
        message: message,
        duration: 2500,
        position: 'top',
        color: 'success',
        mode: 'ios',
        icon: checkmarkCircleOutline,
    });
    toast.present();
}

async function errorToast(message: string) {
    const toast = await toastController.create({
        message: message,
        duration: 2500,
        position: 'top',
        color: 'danger',
        mode: 'ios',
        icon: alertCircleOutline,
    });
    toast.present();
}

async function warningToast(message: string) {
    const toast = await toastController.create({
        message: message,
        duration: 2500,
        position: 'top',
        color: 'warning',
        mode: 'ios',
        icon: alertCircleOutline,
    });
    toast.present();
}

async function infoToast(message: string) {
    const toast = await toastController.create({
        message: message,
        duration: 2500,
        position: 'top',
        color: 'primary',
        mode: 'ios',
        icon: alertCircleOutline,
    });
    toast.present();
}

async function infoActionToast(message: string, action: string, callback: any) {
    const toast = await toastController.create({
        message: message,
        duration: 2500,
        position: 'top',
        color: 'primary',
        mode: 'ios',
        icon: alertCircleOutline,
        buttons: [
            {
                text: action,
                handler: () => {
                    callback();
                    toast.dismiss();
                },
            },
        ],
    });
    toast.present();
}

export { successToast, errorToast, warningToast, infoToast, infoActionToast };
