import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_ctx.selectedProperty.rental_property.house_manual)
    ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "section-title" }, {
                default: _withCtx(() => [
                  _createTextVNode("House Manual")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_card_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { style: {"white-space":"pre-wrap","overflow-wrap":"break-word"} }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.manualText), 1),
                      (_ctx.isShortManual)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isShortManual = false)),
                            style: {"text-decoration":"underline","cursor":"pointer"}
                          }, "Read More"))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}