import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"100px","padding-top":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckInOutCard = _resolveComponent("CheckInOutCard")!
  const _component_CheckOutTasks = _resolveComponent("CheckOutTasks")!
  const _component_PropertyCustomVariables = _resolveComponent("PropertyCustomVariables")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, {
    showPropertySegment: "",
    title: "Departure Details"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CheckInOutCard, { isCheckOut: "" }),
        _createVNode(_component_CheckOutTasks),
        _createVNode(_component_PropertyCustomVariables, { location: 3 })
      ])
    ]),
    _: 1
  }))
}