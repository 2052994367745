<template>
    <modal-base-layout title="Guest Review" styleString="height: 100%;">
        <SurveyComponent :model="survey" style="width: 100%; height: 100%" />
    </modal-base-layout>
</template>

<script lang="ts">
    import 'survey-core/defaultV2.min.css';
    import { defineComponent, ref } from 'vue';
    import { errorToast, successToast } from '@/services/ToastController';
    import { closeOutline } from 'ionicons/icons';
    import { Model } from 'survey-core';
    import AppAPI from '@/services/AppAPI';
    import { modalController } from '@ionic/vue';
    import { useStore } from '@/store/store';

    export default defineComponent({
        components: {},
        props: {
            data: {
                type: Object as () => any,
                default: () => {
                    return {};
                },
            },
        },
        setup(props) {
            const surveyJSON = ref(props.data.hosted_form.form_json);
            const store = useStore();

            const submitSurvey = async (survey: any) => {
                try {
                    const data = survey.getPlainData().map(({ title, value, name, displayValue }: any) => {
                        const json = surveyJSON.value as any;
                        const type = json.elements.find((item: any) => item.name === name)?.type;
                        let inputValue = displayValue;
                        if (type === 'checkbox') {
                            inputValue = json.elements
                                .find((item: any) => item.name === name)
                                ?.choices.filter((item: any) => value.includes(item.value || item))
                                .map((item: any) => item.text || item)
                                .join(';;');
                        }
                        return {
                            title: title,
                            displayValue: inputValue || 'N/A',
                            type,
                        };
                    });

                    await AppAPI.saveHostedForm(data, props.data.uuid);
                    const reservation = (await AppAPI.getReservationData(store.selectedReservationId)).data;
                    store.reservationData = reservation;
                    successToast('Submitted successfully');
                } catch (e) {
                    console.log(e);
                    errorToast('Submission failed');
                }

                return modalController.dismiss(null, 'cancel');
            };

            const survey = new Model(surveyJSON.value);
            survey.applyTheme({
                cssVariables: {
                    '--sjs-general-backcolor': 'rgba(255, 255, 255, 1)',
                    '--sjs-general-backcolor-dark': 'rgba(248, 248, 248, 1)',
                    '--sjs-general-backcolor-dim': 'rgba(255,250,241, 0)',
                    '--sjs-general-backcolor-dim-light': 'rgba(249, 249, 249, 1)',
                    '--sjs-general-backcolor-dim-dark': 'rgba(243, 243, 243, 1)',
                    '--sjs-general-forecolor': 'rgba(0, 0, 0, 0.91)',
                    '--sjs-general-forecolor-light': 'rgba(0, 0, 0, 0.45)',
                    '--sjs-general-dim-forecolor': 'rgba(0, 0, 0, 0.91)',
                    '--sjs-general-dim-forecolor-light': 'rgba(0, 0, 0, 0.45)',
                    '--sjs-primary-backcolor': '#24b4a7',
                    '--sjs-primary-backcolor-light': 'rgba(142,182,155, 0.07)',
                    '--sjs-primary-backcolor-dark': 'rgba(142,182,155, 1)',
                    '--sjs-primary-forecolor': 'rgba(255, 255, 255, 1)',
                    '--sjs-primary-forecolor-light': 'rgba(255, 255, 255, 0.25)',
                    '--sjs-base-unit': '8px',
                    '--sjs-corner-radius': '4px',
                    '--sjs-secondary-backcolor': 'rgba(255, 152, 20, 1)',
                    '--sjs-secondary-backcolor-light': 'rgba(255, 152, 20, 0.1)',
                    '--sjs-secondary-backcolor-semi-light': 'rgba(255, 152, 20, 0.25)',
                    '--sjs-secondary-forecolor': 'rgba(255, 255, 255, 1)',
                    '--sjs-secondary-forecolor-light': 'rgba(255, 255, 255, 0.25)',
                    '--sjs-shadow-small': '0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
                    '--sjs-shadow-medium': '0px 2px 6px 0px rgba(0, 0, 0, 0.1)',
                    '--sjs-shadow-large': '0px 8px 16px 0px rgba(0, 0, 0, 0.1)',
                    '--sjs-shadow-inner': 'inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
                    '--sjs-border-light': 'rgba(0, 0, 0, 0.09)',
                    '--sjs-border-default': 'rgba(0, 0, 0, 0.16)',
                    '--sjs-border-inside': 'rgba(0, 0, 0, 0.16)',
                    '--sjs-special-red': 'rgba(229, 10, 62, 1)',
                    '--sjs-special-red-light': 'rgba(229, 10, 62, 0.1)',
                    '--sjs-special-red-forecolor': 'rgba(255, 255, 255, 1)',
                    '--sjs-special-green': 'rgba(25, 179, 148, 1)',
                    '--sjs-special-green-light': 'rgba(25, 179, 148, 0.1)',
                    '--sjs-special-green-forecolor': 'rgba(255, 255, 255, 1)',
                    '--sjs-special-blue': 'rgba(67, 127, 217, 1)',
                    '--sjs-special-blue-light': 'rgba(67, 127, 217, 0.1)',
                    '--sjs-special-blue-forecolor': 'rgba(255, 255, 255, 1)',
                    '--sjs-special-yellow': 'rgba(255, 152, 20, 1)',
                    '--sjs-special-yellow-light': 'rgba(255, 152, 20, 0.1)',
                    '--sjs-special-yellow-forecolor': 'rgba(255, 255, 255, 1)',
                },
                isPanelless: false,
            });

            survey.onComplete.add(submitSurvey);

            return {
                closeOutline,
                survey,
            };
        },
    });
</script>
