<template>
    <modal-base-layout
        :showCloseButton="store.selectedReservationId != ''"
        :title="store.selectedReservationId ? 'Change Stay' : 'Select Stay'"
    >
        <div v-for="i in 3" :key="i" style="padding-top: 10px">
            <ion-label
                v-if="i == 1"
                style="font-weight: bold; display: flex; justify-content: left; padding-left: 15px; font-size: 20px"
                >Current Stays</ion-label
            >
            <ion-label
                v-if="i == 2"
                style="font-weight: bold; display: flex; justify-content: left; padding-left: 15px; font-size: 20px"
                >Upcoming Stays</ion-label
            >
            <ion-label
                v-if="i == 3"
                style="font-weight: bold; display: flex; justify-content: left; padding-left: 15px; font-size: 20px"
                >Previous Stays</ion-label
            >
            <template v-for="(item, index) in sortedReservations" :key="index">
                <ion-card
                    style="cursor: pointer; margin: 15px"
                    @click="swapReservation(item.id)"
                    v-if="
                        (isCurrentReservation(item) && i == 1) ||
                        (calculateDaysDifferenceFromToday(item.from_date) >= 1 && i == 2) ||
                        (calculateDaysDifferenceFromToday(item.to_date) <= -1 && i == 3)
                    "
                >
                    <ion-card-content style="margin: 10px; background-color: transparent">
                        <div v-if="i == 3" style="padding-bottom: 10px">
                            <ion-label style="font-weight: bold; font-size: 14px">Past Stay</ion-label>
                        </div>
                        <div v-if="i == 2" style="padding-bottom: 10px">
                            <ion-label style="font-weight: bold; font-size: 14px">Upcoming Stay</ion-label>
                        </div>
                        <div style="display: flex; justify-content: space-between">
                            <ion-img
                                style="
                                    min-width: 125px;
                                    max-width: 125px;
                                    min-height: 125px;
                                    max-height: 125px;
                                    object-fit: cover;
                                    border-radius: 5px;
                                    overflow: hidden;
                                "
                                :src="
                                    item.tech_reservations[0]?.rental_property.thumbnail_url ||
                                    'assets/img/no_image.png'
                                "
                            />
                            <div
                                style="
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;
                                    width: 100%;
                                    margin-left: 10px;
                                "
                            >
                                <div style="display: flex; flex-direction: column; width: 100%">
                                    <ion-label style="font-weight: bold">Invoice #{{ item.id }}</ion-label>
                                    <ion-label style="font-size: 14px"
                                        >{{ formatReservationDate(item.from_date) }} -
                                        {{ formatReservationDate(item.to_date) }}</ion-label
                                    >
                                    <div
                                        v-for="(property, indexProperty) in item.tech_reservations"
                                        :key="indexProperty"
                                        style="
                                            background-color: var(--ion-color-light-tint);
                                            width: fit-content;
                                            padding: 4px;
                                            margin: 1px 0px;
                                            display: flex;
                                            align-items: center;
                                        "
                                    >
                                        <ion-label style="font-size: 12px; font-weight: bold">{{
                                            property.rental_property.name
                                        }}</ion-label>
                                    </div>
                                </div>

                                <div
                                    v-if="item.shared_users > 0 && !AppFunctions.isImpersonating()"
                                    style="width: fit-content; display: flex; align-items: center"
                                >
                                    <ion-icon :src="shareSocialOutline" />
                                    <ion-label style="font-size: 14px">{{ item.shared_users }} Shares</ion-label>
                                </div>
                            </div>
                        </div>
                    </ion-card-content>
                    <ion-chip
                        style="
                            background: var(--ion-color-primary);
                            max-width: 100%;
                            max-height: 40px;
                            height: auto;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0px;
                            margin: 0px;
                            border-radius: 0px;
                        "
                        v-if="store.selectedReservationId == item.id && !store.viewingChatWithoutReservation"
                    >
                        <span style="font-weight: bold; color: white"> Currently Selected </span>
                    </ion-chip>
                </ion-card>
            </template>

            <ion-card
                style="margin: 15px"
                v-if="
                    (i == 1 && numberOfCurrentReservations <= 0) ||
                    (i == 2 && numberOfUpcomingReservations <= 0) ||
                    (i == 3 && numberOfPreviousReservations <= 0)
                "
            >
                <ion-card-content style="margin: 10px; background-color: transparent; height: 125px">
                    <div style="display: flex; justify-content: center; align-items: center; height: 100%">
                        <ion-label
                            v-if="i == 1 && numberOfCurrentReservations <= 0"
                            style="font-weight: bold; font-size: 18px; padding: 15px"
                        >
                            No Current Stays
                        </ion-label>
                        <ion-label
                            v-if="i == 2 && numberOfUpcomingReservations <= 0"
                            style="font-weight: bold; font-size: 18px; padding: 15px"
                        >
                            No Upcoming Stays
                        </ion-label>
                        <ion-label
                            v-if="i == 3 && numberOfPreviousReservations <= 0"
                            style="font-weight: bold; font-size: 18px; padding: 15px"
                        >
                            No Previous Stays
                        </ion-label>
                    </div>
                </ion-card-content>
            </ion-card>
        </div>

        <ion-card>
            <ion-card-content style="margin: 15px; display: flex; flex-direction: column">
                <ion-label style="font-weight: bold">Not seeing a certain stay?</ion-label>
                <ion-label>Try signing out and logging in with the details of the stay needed.</ion-label>
                <ion-button color="danger" style="width: 100%; margin-top: 15px" @click="signout">Sign Out</ion-button>
            </ion-card-content>
        </ion-card>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import {
        checkmarkOutline,
        closeOutline,
        copyOutline,
        openOutline,
        star,
        peopleCircleOutline,
        shareSocialOutline,
    } from 'ionicons/icons';
    import { computed, defineComponent, onMounted, ref } from 'vue';
    import { useStore } from '@/store/store';
    import { basicAlert } from '@/services/AlertController';
    import { basicActionSheet } from '@/services/ActionController';
    import AppAPI from '@/services/AppAPI';
    import { hideLoading, showLoading } from '@/services/LoadingController';
    import AppFunctions from '@/services/AppFunctions';
    import { logout } from '@/services/Authentication';
    import { calculateDaysDifferenceFromToday } from '@/services/Date';
    import { isMonthFirst } from '@/services/Date';

    export default defineComponent({
        components: {
            //
        },
        props: {
            noRefresh: {
                type: Boolean,
                default: false,
                required: false,
            },
        },
        setup(props) {
            const ionRouter = useIonRouter();
            const store = useStore();

            onMounted(async () => {
                if (!props.noRefresh) {
                    const loading = await showLoading('Loading...');
                    await AppFunctions.refreshReservationList();
                    hideLoading(loading);
                }
            });

            const formatReservationDate = (date: string) => {
                const year = date.split('-')[0];
                const month = date.split('-')[1];
                const day = date.split('-')[2];
                if (isMonthFirst()) {
                    return `${month}/${day}/${year}`;
                }
                return `${day}/${month}/${year}`;
            };

            const sortedReservations = computed((i: any) => {
                return store.allReservations.slice().sort((a: any, b: any) => {
                    if (calculateDaysDifferenceFromToday(a.to_date) > 0) {
                        return new Date(b.from_date).getTime() + new Date(a.from_date).getTime();
                    } else {
                        return new Date(b.from_date).getTime() - new Date(a.from_date).getTime();
                    }
                });
            });

            const numberOfUpcomingReservations = computed(() => {
                return store.allReservations.filter((reservation: any) => {
                    return calculateDaysDifferenceFromToday(reservation.from_date) >= 1;
                }).length;
            });

            const numberOfPreviousReservations = computed(() => {
                return store.allReservations.filter((reservation: any) => {
                    return calculateDaysDifferenceFromToday(reservation.to_date) <= -1;
                }).length;
            });

            const numberOfCurrentReservations = computed(() => {
                return store.allReservations.filter((reservation: any) => {
                    return isCurrentReservation(reservation);
                }).length;
            });

            const isCurrentReservation = (item: any) => {
                const result =
                    calculateDaysDifferenceFromToday(item.to_date) > -1 &&
                    calculateDaysDifferenceFromToday(item.from_date) < 1;
                return result;
            };

            const swapReservation = async (id: string) => {
                const loading2 = await showLoading('Loading...');

                try {
                    await store.changeSelectedReservationId(id);
                    const reservation = (await AppAPI.getReservationData(store.selectedReservationId)).data;
                    store.reservationData = reservation;
                    store.selectedTechReservationId = reservation.tech_reservations[0].id;
                    await AppFunctions.getHostedForms();

                    hideLoading(loading2);
                } catch (error) {
                    console.log(error);
                    hideLoading(loading2);
                }
                return modalController.dismiss(null, 'cancel');
            };

            const signout = async () => {
                await logout();
                ionRouter.replace('/');
                return modalController.dismiss(null, 'cancel');
            };

            return {
                ionRouter,
                closeOutline,
                store,
                openOutline,
                star,
                copyOutline,
                checkmarkOutline,
                sortedReservations,
                AppAPI,
                signout,
                formatReservationDate,
                basicAlert,
                basicActionSheet,
                swapReservation,
                calculateDaysDifferenceFromToday,
                isCurrentReservation,
                numberOfCurrentReservations,
                numberOfUpcomingReservations,
                numberOfPreviousReservations,
                peopleCircleOutline,
                AppFunctions,
                shareSocialOutline,
            };
        },
    });
</script>

<style scoped>
    ion-row {
        margin-bottom: 5px;
    }
</style>
