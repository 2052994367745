<template>
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 430 419"
        enable-background="new 0 0 430 419"
        xml:space="preserve"
        fill="currentColor"
        height="25"
        width="25"
    >
        <path
            opacity="1.000000"
            stroke="none"
            d="
M338.149597,356.830414 
	C335.592255,341.983307 333.171936,327.550018 330.481934,313.167206 
	C330.017914,310.686188 328.925385,307.942169 327.248840,306.127319 
	C310.068024,287.528748 292.708557,269.095154 275.389526,250.624329 
	C274.609955,249.792892 273.740417,249.045776 272.119537,247.505661 
	C271.318085,252.018295 270.595490,255.788422 269.984680,259.576538 
	C267.020416,277.961273 264.359894,296.401459 260.971710,314.707275 
	C259.975555,320.089355 257.725494,325.605682 254.719772,330.187866 
	C239.371841,353.585480 223.576431,376.689362 207.964539,399.914124 
	C203.349075,406.780243 197.055649,410.448212 188.812698,408.627350 
	C180.402603,406.769562 173.951447,401.635864 172.840607,392.800323 
	C172.294174,388.454071 173.640839,383.009491 176.040482,379.295929 
	C189.418335,358.593262 203.051651,338.034760 217.314758,317.935883 
	C222.602280,310.484955 224.621918,302.532928 225.938324,293.948029 
	C229.945251,267.817200 233.745056,241.646820 238.347809,215.619568 
	C239.928711,206.680069 243.607483,198.106979 246.387039,189.385345 
	C249.268616,180.343521 252.231705,171.327682 255.132233,162.291840 
	C255.520676,161.081741 255.751526,159.821075 255.247208,158.113358 
	C249.052063,165.425568 242.828033,172.713547 236.667831,180.055054 
	C223.930008,195.235489 211.473022,210.663757 198.316162,225.470856 
	C195.416489,228.734207 190.092529,229.852554 185.869370,231.927002 
	C183.769333,232.958542 181.004349,233.377045 179.613190,234.995316 
	C167.129593,249.516800 154.843414,264.209381 142.653915,278.979340 
	C141.748322,280.076630 141.413742,282.375702 141.882126,283.752625 
	C144.168564,290.474182 143.188202,296.254059 138.814667,301.998932 
	C122.371857,323.597321 106.280975,345.463684 89.832031,367.057343 
	C85.743286,372.424896 80.805435,377.145630 75.872826,382.588562 
	C71.623589,376.926331 66.701294,372.875610 59.556442,373.042755 
	C52.685589,373.203461 47.674465,376.763855 43.455296,382.712128 
	C33.978466,375.013702 24.506584,367.752625 15.584859,359.868958 
	C11.752482,356.482544 10.502446,351.454926 11.429323,346.080933 
	C11.708462,344.462463 11.412230,342.458923 10.664237,340.998016 
	C7.183249,334.199280 7.721824,327.979523 12.350571,321.874573 
	C23.921583,306.613281 35.329063,291.228058 46.881474,275.952576 
	C53.423801,267.301788 59.309349,265.836487 70.960693,269.945770 
	C73.960587,265.993042 77.072441,261.919098 80.156334,257.824127 
	C87.215660,248.450378 96.340523,247.133774 105.737808,254.191284 
	C112.795357,259.491608 119.896690,264.738678 126.821510,270.208252 
	C129.777649,272.543182 131.704178,272.458069 134.192993,269.419006 
	C145.376450,255.762985 156.725601,242.241379 168.136292,228.774078 
	C169.930176,226.656876 170.178879,225.278412 168.559708,222.788864 
	C163.367538,214.805618 164.028000,207.589661 170.121628,200.314438 
	C200.168930,164.440659 230.211533,128.562927 260.287231,92.712975 
	C265.259796,86.785759 271.831879,84.868217 279.199799,86.393417 
	C296.626526,90.000862 313.954834,94.092430 331.408813,97.555542 
	C339.889557,99.238228 344.946075,104.078255 347.760559,111.890587 
	C354.531982,130.686630 361.234436,149.509003 368.242859,168.216537 
	C369.270844,170.960541 371.372742,173.748215 373.738617,175.470383 
	C386.259827,184.584747 399.018860,193.372726 411.706390,202.258209 
	C421.295563,208.973785 423.605957,219.316818 417.487549,228.257889 
	C411.451630,237.078384 400.729645,238.726456 391.160492,232.086578 
	C374.602844,220.597534 357.972321,209.200134 341.752167,197.248825 
	C338.294800,194.701401 335.676605,190.264069 334.028870,186.153427 
	C329.206055,174.122223 325.067993,161.816528 320.154724,148.214951 
	C318.410095,154.555588 316.996368,159.586182 315.642609,164.632904 
	C311.072418,181.670456 306.688965,198.760925 301.852051,215.722198 
	C300.619354,220.044769 301.646210,222.861511 304.520386,225.906235 
	C318.927399,241.167984 333.167633,256.586945 347.529449,271.891602 
	C369.114227,294.893372 364.452820,286.832520 369.950989,316.705261 
	C374.014374,338.782410 377.815308,360.907776 381.748627,383.008911 
	C383.615387,393.498047 380.039520,401.721771 371.971649,405.392181 
	C363.245758,409.361938 352.710388,407.626526 348.174377,400.180023 
	C345.355652,395.552734 344.315125,389.695953 343.137939,384.248047 
	C341.207397,375.313629 339.814850,366.262970 338.149597,356.830414 
z"
        />
        <path
            opacity="1.000000"
            stroke="none"
            d="
M287.838257,27.922974 
	C297.310181,14.900353 309.888550,9.064667 325.355743,11.533535 
	C341.230133,14.067405 351.686829,23.632488 356.161652,39.246372 
	C362.319733,60.733528 348.103821,82.404472 325.178711,86.645622 
	C304.827759,90.410545 284.195618,74.487625 281.847046,53.442524 
	C280.823486,44.270695 282.895935,35.983879 287.838257,27.922974 
z"
        />
        <path
            opacity="1.000000"
            stroke="none"
            d="
M44.846878,389.821899 
	C47.126099,381.637421 53.384686,376.655701 60.247749,377.227081 
	C66.991379,377.788544 73.161324,383.361877 73.978851,389.630463 
	C74.966019,397.199707 71.175224,403.660950 64.249008,406.214630 
	C54.019444,409.986176 43.652180,401.458099 44.846878,389.821899 
z"
        />
    </svg>
</template>
