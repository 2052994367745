<template>
    <div v-if="status == 'PAID'" class="statusCard" style="background: rgba(20, 170, 0, 0.2)">
        <p style="font-size: x-large; color: rgb(2, 183, 106); font-weight: bold; align-items: center; display: flex">
            <img style="width: 30px; margin-right: 10px" src="@/assets/icons/reservation-paid.svg" />Paid In Full
        </p>
        <p style="align-items: center">
            Invoice #{{ store.reservationData.id }} <br /><br />
            {{ store.reservationData.booking_method_name }} • {{ formatDate(store.reservationData.created) }}
        </p>
    </div>
    <div v-if="status == 'PAIDDEPOSIT'" class="statusCard" style="background: rgba(243, 174, 61, 0.2)">
        <p style="font-size: x-large; color: rgb(239, 172, 2); font-weight: bold; align-items: center; display: flex">
            <img style="width: 30px; margin-right: 10px" src="@/assets/icons/reservation-confirmed.svg" />Confirmed
        </p>
        <p>
            Invoice #{{ store.reservationData.id }} <br /><br />
            {{ store.reservationData.booking_method_name }} • {{ formatDate(store.reservationData.created) }}
        </p>
    </div>
    <div v-if="status == 'NOTPAID'" class="statusCard" style="background: rgba(255, 0, 0, 0.2)">
        <p style="font-size: x-large; color: rgb(255, 93, 93); font-weight: bold; align-items: center; display: flex">
            <img style="width: 30px; margin-right: 10px" src="@/assets/icons/reservation-unconfirmed.svg" />Unconfirmed
        </p>
        <p>
            Invoice #{{ store.reservationData.id }} <br /><br />
            {{ store.reservationData.booking_method_name }} • {{ formatDate(store.reservationData.created) }}
        </p>
    </div>
    <div v-if="status == 'CANCELLED'" class="statusCard" style="background: rgba(127, 127, 127, 0.2)">
        <p style="font-size: x-large; color: rgb(116, 116, 116); font-weight: bold; align-items: center; display: flex">
            <img style="width: 30px; margin-right: 10px" src="@/assets/icons/reservation-cancelled.svg" />Cancelled
        </p>
        <p>
            Invoice #{{ store.reservationData.id }} <br /><br />
            {{ store.reservationData.booking_method_name }} • {{ formatDate(store.reservationData.created) }}
        </p>
    </div>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { defineComponent, computed } from 'vue';
    import { closeOutline } from 'ionicons/icons';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';
    import { formatDate } from '@/services/Date';

    export default defineComponent({
        components: {},
        setup() {
            const store = useStore();
            const ionRouter = useIonRouter();
            const status = computed(() => {
                return store.reservationData.status;
            });

            const cancel = () => {
                return modalController.dismiss(null, 'cancel');
            };

            return {
                ionRouter,
                closeOutline,
                store,
                AppFunctions,
                status,
                formatDate,
                cancel,
            };
        },
    });
</script>

<style scoped>
    .statusCard {
        border-radius: 10px;
        margin: 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
</style>
