<template>
    <div>
        <ion-card>
            <ion-card-header>
                <ion-label class="section-title">Description</ion-label>
            </ion-card-header>
            <ion-card-content>
                <ion-item lines="none">
                    <ion-label style="white-space: pre-wrap; overflow-wrap: break-word">
                        {{ descriptionText }}
                        <span
                            v-if="isShortDescription"
                            @click="isShortDescription = false"
                            style="text-decoration: underline; cursor: pointer"
                            >Read More</span
                        >
                    </ion-label>
                </ion-item>
            </ion-card-content>
        </ion-card>
    </div>
</template>

<script lang="ts">
    import { defineComponent, computed, ref, watch } from 'vue';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';

    export default defineComponent({
        setup() {
            const store = useStore();
            const isShortDescription = ref(
                AppFunctions.getSelectedPropertyData().rental_property.description.length > 200
            );

            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            const descriptionText = computed(() => {
                if (
                    AppFunctions.getSelectedPropertyData().rental_property.description.length > 200 &&
                    isShortDescription.value
                ) {
                    return AppFunctions.getSelectedPropertyData().rental_property.description.substring(0, 200) + '...';
                } else {
                    return AppFunctions.getSelectedPropertyData().rental_property.description;
                }
            });

            watch(
                () => store.selectedTechReservationId,
                () => {
                    isShortDescription.value =
                        AppFunctions.getSelectedPropertyData().rental_property.description.length > 200;
                }
            );

            return {
                selectedProperty,
                store,
                isShortDescription,
                descriptionText,
            };
        },
    });
</script>
