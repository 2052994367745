import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SurveyComponent = _resolveComponent("SurveyComponent")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, {
    title: "Guest Review",
    styleString: "height: 100%;"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_SurveyComponent, {
        model: _ctx.survey,
        style: {"width":"100%","height":"100%"}
      }, null, 8, ["model"])
    ]),
    _: 1
  }))
}