import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"margin":"10px 15px"}
}
const _hoisted_2 = { style: {"width":"100%","text-align":"center","margin-top":"15px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, { title: "Guest Verification" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_header, null, {
            default: _withCtx(() => [
              (_ctx.store.user.is_verification_enabled)
                ? (_openBlock(), _createBlock(_component_ion_label, {
                    key: 0,
                    class: "section-title"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Status: " + _toDisplayString(_ctx.store.user.is_verified ? 'Verified' : 'Unverified'), 1)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_ion_label, {
                    key: 1,
                    class: "section-title"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Guest Verification Not Available")
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          }),
          _createVNode(_component_ion_card_content, null, {
            default: _withCtx(() => [
              (_ctx.store.user.is_verification_enabled)
                ? (_openBlock(), _createBlock(_component_ion_list, {
                    key: 0,
                    lines: "none"
                  }, {
                    default: _withCtx(() => [
                      (!_ctx.store.user.is_verified)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode("A valid photo ID is required for all bookings. Please upload a valid photo ID using the start verification button below.")
                              ]),
                              _: 1
                            }),
                            _createElementVNode("div", _hoisted_2, [
                              _createVNode(_component_ion_button, {
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openLink()))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Start Verification")
                                ]),
                                _: 1
                              })
                            ])
                          ]))
                        : (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("No further action is required.")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_ion_list, {
                    key: 1,
                    lines: "none"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("This property manager does not require guest verification.")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}