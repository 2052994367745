import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          (_ctx.accessToken)
            ? (_openBlock(), _createBlock(_component_ion_tab_bar, {
                key: 0,
                id: "footer",
                slot: "bottom"
              }, {
                default: _withCtx(() => [
                  (!_ctx.AppFunctions.isImpersonating())
                    ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                        key: 0,
                        tab: "messages",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openMessages()))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            "aria-hidden": "true",
                            icon: 
                            _ctx.router.currentRoute.value.path.includes('/tabs/messages')
                                ? _ctx.chatbubbleEllipses
                                : _ctx.chatbubbleEllipsesOutline
                        ,
                            color: _ctx.router.currentRoute.value.path.includes('/tabs/messages') ? 'primary' : 'light'
                          }, null, 8, ["icon", "color"]),
                          _createVNode(_component_ion_label, { class: "footer-label" }, {
                            default: _withCtx(() => [
                              _createTextVNode("Chat")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_tab_button, {
                    tab: "reservation",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.swapReservation()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        "aria-hidden": "true",
                        icon: _ctx.router.currentRoute.value.path.includes('/tabs/reservation') ? _ctx.home : _ctx.homeOutline,
                        color: _ctx.router.currentRoute.value.path.includes('/tabs/reservation') ? 'primary' : 'light'
                      }, null, 8, ["icon", "color"]),
                      _createVNode(_component_ion_label, { class: "footer-label" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Stays")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_tab_button, {
                    tab: "settings",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openSettings()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        "aria-hidden": "true",
                        icon: 
                            _ctx.router.currentRoute.value.path.includes('/tabs/settings')
                                ? _ctx.personCircle
                                : _ctx.personCircleOutline
                        ,
                        color: _ctx.router.currentRoute.value.path.includes('/tabs/settings') ? 'primary' : 'light'
                      }, null, 8, ["icon", "color"]),
                      _createVNode(_component_ion_label, { class: "footer-label" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Profile")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}