import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsManager from '@/components/TabsManager.vue';
import LoginPage from '@/views/LoginPage.vue';
import ReservationTabPage from '@/views/ReservationTabPage.vue';
import SettingsTabPage from '@/views/SettingsTabPage.vue';
import MessagesTabPage from '@/views/MessagesTabPage.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/tabs/reservation',
    },
    {
        path: '/tabs/',
        component: TabsManager,
        children: [
            {
                path: '',
                redirect: '/tabs/reservation',
            },
            {
                path: 'reservation/:pathMatch(.*)*',
                component: ReservationTabPage,
            },
            {
                path: 'messages/:pathMatch(.*)*',
                component: MessagesTabPage,
            },
            {
                path: 'settings/:pathMatch(.*)*',
                component: SettingsTabPage,
            },
        ],
    },
    {
        path: '/login',
        component: LoginPage,
    },
    {
        path: '/impersonate/:token',
        component: LoginPage,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        redirect: '/tabs/reservation?notFound=true',
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
