<template>
    <div v-if="propertyCustomVariables">
        <template v-for="variable in propertyCustomVariables" :key="variable">
            <ion-card>
                <ion-card-header>
                    <ion-label class="section-title">{{ variable.title }}</ion-label>
                </ion-card-header>
                <ion-card-content style="margin: 10px 0">
                    <ion-item lines="none">
                        <div v-html="generateHtml(variable.value)" />
                    </ion-item>
                </ion-card-content>
            </ion-card>
        </template>
    </div>
</template>

<script lang="ts">
    import { defineComponent, computed } from 'vue';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';
    import { checkUrl, isHTTPS } from '@/services/Utils';
    import { advancedAlert } from '@/services/AlertController';
    import { Browser } from '@capacitor/browser';

    export default defineComponent({
        props: {
            location: {
                type: Number,
                default: 0,
            },
        },
        setup(props) {
            const store = useStore();

            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            const openLink = (url: string) => {
                const isHttps = isHTTPS(url);
                const title = 'External Link';
                const httpMessage = 'This link is not HTTPS secure.';
                const message = `You are about to leave the safety of the Staylio app to visit a third-party site.  Please proceed with caution.`;
                advancedAlert(title, isHttps ? '' : httpMessage, message, [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                    },
                    {
                        text: 'Continue',
                        handler: () => {
                            Browser.open({ url });
                        },
                    },
                ]);
            };

            const generateHtml = (value: string) => {
                if (value && !value.includes('<')) {
                    value = value.replace(/\n/g, '<br />');
                    const linkRegex = /https?:\/\/[^\s<]+(?![^<]*>)/g;
                    value = value.replace(linkRegex, (url: string) => {
                        return `<a style="cursor:pointer;" onclick="openLinkHandler('${url}')">${url}</a>`;
                    });
                }
                return value;
            };

            const propertyCustomVariables = computed(() => {
                let items = [];
                items = selectedProperty.value.rental_property.property_custom_variables || [];
                if (location && items) {
                    items = items.filter((item: any) => item.guestapp_location == props.location && item.value != '');
                    items.forEach((item: any) => {
                        item.value = generateHtml(item.value);
                    });
                }

                return items || [];
            });

            const openLinkHandler = (url: string) => {
                openLink(url);
            };

            (window as any).openLinkHandler = openLinkHandler;

            return {
                selectedProperty,
                store,
                openLink,
                propertyCustomVariables,
                generateHtml,
                openLinkHandler,
            };
        },
    });
</script>
