import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, { title: "Amenities" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "section-title" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.selectedProperty.rental_property.name), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_card_content, { style: {"margin":"5px 15px"} }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      key: index,
                      lines: index == _ctx.items.length - 1 ? 'none' : 'full'
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["lines"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}