<template>
    <modal-base-layout title="Payment">
        <div style="margin-bottom: 100px">
            <PaymentStatusCard />

            <div class="section-header" style="margin-top: 15px; margin-right: 25px">
                <ion-label class="section-title">Confirmation Amount</ion-label>
                <ion-label style="margin-top: 10px">
                    {{ store.reservationData.confirmation_amount }}
                </ion-label>
            </div>
            <div class="section-header" style="margin-top: 15px; margin-right: 25px">
                <ion-label class="section-title">Total Amount</ion-label>
                <ion-label style="margin-top: 10px">
                    {{ store.reservationData.total }}
                </ion-label>
            </div>
            <div class="section-header" style="margin-top: 15px; margin-right: 25px">
                <ion-label class="section-title">Total Amount with Deposits</ion-label>
                <ion-label style="margin-top: 10px">
                    {{ store.reservationData.total_with_deposits }}
                </ion-label>
            </div>
            <div class="section-header" style="margin-top: 15px; margin-right: 25px">
                <ion-label class="section-title">Amount Paid</ion-label>
                <ion-label style="margin-top: 10px">
                    {{ store.reservationData.paid }}
                </ion-label>
            </div>
        </div>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { defineComponent, computed } from 'vue';
    import { closeOutline } from 'ionicons/icons';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';
    import PaymentStatusCard from '../PaymentStatusCard.vue';

    export default defineComponent({
        components: { PaymentStatusCard },
        props: {
            //
        },
        setup() {
            const store = useStore();
            const ionRouter = useIonRouter();
            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            return {
                ionRouter,
                closeOutline,
                store,
                selectedProperty,
                AppFunctions,
            };
        },
    });
</script>
