<template>
    <modal-base-layout showPropertySegment title="Departure Details">
        <div style="margin-bottom: 100px; padding-top: 10px">
            <CheckInOutCard isCheckOut />

            <CheckOutTasks />

            <PropertyCustomVariables :location="3" />
        </div>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { defineComponent, computed, ref, onMounted } from 'vue';
    import { alertCircleOutline, closeOutline, checkmarkCircle, alertCircle } from 'ionicons/icons';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';
    import CheckOutTasks from '@/components/SelectedPropertyComponents/CheckOutTasks.vue';
    import CheckInOutCard from '../SelectedPropertyComponents/CheckInOutCard.vue';
    import PropertyCustomVariables from '../SelectedPropertyComponents/PropertyCustomVariables.vue';

    export default defineComponent({
        components: {
            CheckOutTasks,
            CheckInOutCard,
            PropertyCustomVariables,
        },
        props: {
            //
        },
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();
            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            const cancel = () => {
                return modalController.dismiss(null, 'cancel');
            };

            onMounted(async () => {
                await AppFunctions.refreshCurrentReservationData();
            });

            return {
                ionRouter,
                closeOutline,
                store,
                alertCircleOutline,
                selectedProperty,
                AppFunctions,
                checkmarkCircle,
                alertCircle,
                cancel,
            };
        },
    });
</script>

<style scoped></style>
