import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (
            _ctx.selectedProperty.rental_property.wifi_details.name && _ctx.selectedProperty.rental_property.wifi_details.password
        )
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ion_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_header, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { class: "section-title" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Wi-Fi Details")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card_content, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_list, { lines: "full" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          readonly: "",
                          value: _ctx.selectedProperty.rental_property.wifi_details.name,
                          label: "Wi-Fi Name (SSID)",
                          "label-placement": "stacked"
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          readonly: "",
                          value: _ctx.selectedProperty.rental_property.wifi_details.password,
                          label: "Password",
                          "label-placement": "stacked"
                        }, null, 8, ["value"]),
                        _createVNode(_component_ion_icon, {
                          slot: "end",
                          icon: _ctx.copyOutline,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyWifi(_ctx.selectedProperty.rental_property.wifi_details.password)))
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }),
                    (_ctx.Capacitor.getPlatform() != 'web')
                      ? (_openBlock(), _createBlock(_component_ion_item, {
                          key: 0,
                          lines: "none"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, {
                              style: {"text-align":"center","align-items":"center","display":"flex","justify-content":"center"},
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (
                                _ctx.joinNetwork(
                                    _ctx.selectedProperty.rental_property.wifi_details.name,
                                    _ctx.selectedProperty.rental_property.wifi_details.password
                                )
                            ))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  src: _ctx.wifi,
                                  style: {"padding-right":"10px"}
                                }, null, 8, ["src"]),
                                _createTextVNode("Quick Connect to Wi-Fi")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_ion_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_header, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { class: "section-title" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Wi-Fi Details")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card_content, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_item, { lines: "none" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { style: {"margin":"10px 0px"} }, {
                      default: _withCtx(() => [
                        _createTextVNode("No Wi-Fi details provided")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
}