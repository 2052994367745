<template>
    <ion-card v-if="selectedProperty.rental_property.house_manual">
        <ion-card-header>
            <ion-label class="section-title">House Manual</ion-label>
        </ion-card-header>
        <ion-card-content>
            <ion-item lines="none">
                <ion-label style="white-space: pre-wrap; overflow-wrap: break-word"
                    >{{ manualText
                    }}<span
                        v-if="isShortManual"
                        @click="isShortManual = false"
                        style="text-decoration: underline; cursor: pointer"
                        >Read More</span
                    ></ion-label
                >
            </ion-item>
        </ion-card-content>
    </ion-card>
</template>

<script lang="ts">
    import { defineComponent, computed, ref, watch } from 'vue';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';

    export default defineComponent({
        setup() {
            const store = useStore();

            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            const isShortManual = ref(selectedProperty.value.rental_property.house_manual?.length > 200);

            const manualText = computed(() => {
                if (selectedProperty.value.rental_property.house_manual?.length > 200 && isShortManual.value) {
                    return selectedProperty.value.rental_property.house_manual?.substring(0, 200) + '...';
                } else {
                    return selectedProperty.value.rental_property.house_manual;
                }
            });

            watch(
                () => store.selectedTechReservationId,
                () => {
                    isShortManual.value = selectedProperty.value.rental_property.house_manual?.length > 200;
                }
            );

            return {
                selectedProperty,
                store,
                AppFunctions,
                isShortManual,
                manualText,
            };
        },
    });
</script>
