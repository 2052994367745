<template>
    <modal-base-layout title="Contact Us">
        <ion-card style="margin-bottom: 125px" v-if="company">
            <ion-card-header style="justify-content: center; align-items: center; display: flex; text-align: center">
                <ion-label class="section-title" style="font-size: 25px">{{ company.title }}</ion-label>
                <ion-avatar v-if="company.logo_url" style="width: 150px; height: 150px; margin: 15px 0 25px">
                    <img style="object-fit: contain" :src="company.logo_url" @error="handleImageError" />
                </ion-avatar>
                <ion-avatar slot="start" v-else>
                    <img style="object-fit: contain" :src="staylioLogo" />
                </ion-avatar>
            </ion-card-header>
            <ion-card-content>
                <div
                    class="section-header"
                    style="margin-top: 15px; margin-right: 25px; margin-bottom: 15px"
                    v-if="company.public_phone"
                >
                    <ion-label class="section-title">Phone</ion-label>
                    <ion-item lines="none">
                        <ion-label>{{ company.public_phone }}</ion-label>
                        <ion-icon @click="phoneCall(company.public_phone)" :icon="call" slot="end" />
                    </ion-item>
                </div>
                <div
                    class="section-header"
                    style="margin-top: 15px; margin-right: 25px; margin-bottom: 15px"
                    v-if="company.public_email"
                >
                    <ion-label class="section-title">Email</ion-label>
                    <ion-item lines="none">
                        <ion-label>{{ company.public_email }}</ion-label>
                        <ion-icon @click="sendEmail(company.public_email)" :icon="mail" slot="end" />
                    </ion-item>
                </div>
                <div
                    class="section-header"
                    style="margin-top: 15px; margin-right: 25px; margin-bottom: 15px"
                    v-if="company.public_website"
                >
                    <ion-label class="section-title">Website</ion-label>
                    <ion-item lines="none">
                        <ion-label>{{ company.public_website }}</ion-label>
                        <ion-icon @click="openLink(company.public_website)" :icon="exit" slot="end" />
                    </ion-item>
                </div>
                <div
                    class="section-header"
                    style="margin-top: 15px; margin-right: 25px; margin-bottom: 15px"
                    v-if="company.address_obj"
                >
                    <ion-label class="section-title">Address</ion-label>
                    <ion-label>{{ company.address_obj.address1 + ' ' + company.address_obj.short_name }}</ion-label>
                </div>
                <div
                    class="section-header"
                    style="margin-top: 15px; margin-right: 25px; margin-bottom: 15px"
                    v-if="company.deposit_policy"
                >
                    <ion-label class="section-title">Deposit Policy</ion-label>
                    <ion-label id="deposit_policy">{{ company.deposit_policy }}</ion-label>
                </div>
                <div
                    class="section-header"
                    style="margin-top: 15px; margin-right: 25px; margin-bottom: 15px"
                    v-if="company.cancellation_policy"
                >
                    <ion-label class="section-title">Cancellation Policy</ion-label>
                    <ion-label id="cancellation_policy">{{ company.cancellation_policy }}</ion-label>
                </div>
            </ion-card-content>
        </ion-card>
        <div class="bottom-button-fixed" v-if="!AppFunctions.isImpersonating()">
            <ion-button style="width: 175px; font-weight: bold" @click="openChat()">Chat</ion-button>
        </div>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, ref } from 'vue';
    import {
        bicycle,
        closeOutline,
        home,
        restaurant,
        trailSign,
        chevronForward,
        call,
        mail,
        exit,
    } from 'ionicons/icons';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';

    export default defineComponent({
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();
            const company = ref(store.reservationData.company || {});
            const staylioLogo = ref('https://staylio.com/wp-content/uploads/2024/02/Staylio-Logo-Symbol-78x78.png');

            const handleImageError = (e: any) => {
                console.log('error', e);
                e.target.src = staylioLogo.value;
            };

            const openChat = async () => {
                const conversation = '/conversation/' + store.reservationData.conversation_id;
                const url = '/tabs/messages' + (store.reservationData.conversation_id ? conversation : '/');
                ionRouter.replace(url);
                await modalController.dismiss();
            };

            const sendEmail = (email: any) => {
                window.open('mailto:' + email);
            };

            const phoneCall = (phone: any) => {
                window.open('tel:' + phone);
            };

            const openLink = (link: any) => {
                if (!link.startsWith('http://') && !link.startsWith('https://')) {
                    link = 'https://' + link;
                }
                AppFunctions.openLink(link);
            };

            onMounted(() => {
                const cancellation_policy = document.getElementById('cancellation_policy');
                if (cancellation_policy) {
                    cancellation_policy.innerHTML = store.reservationData.company?.cancellation_policy;
                }

                const deposit_policy = document.getElementById('deposit_policy');
                if (deposit_policy) {
                    deposit_policy.innerHTML = store.reservationData.company?.deposit_policy;
                }
            });

            return {
                ionRouter,
                closeOutline,
                store,
                bicycle,
                chevronForward,
                restaurant,
                trailSign,
                home,
                company,
                call,
                mail,
                exit,
                AppFunctions,
                staylioLogo,
                handleImageError,
                openChat,
                openLink,
                phoneCall,
                sendEmail,
            };
        },
    });
</script>

<style scoped>
    ion-item {
        --padding-start: 0;
        --padding-end: 0;
        --inner-padding-end: 0;
        --inner-padding-start: 0;
    }

    ion-icon {
        color: var(--ion-color-primary);
    }
</style>
