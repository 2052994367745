import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c205399"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_ctx.accessToken)
    ? (_openBlock(), _createBlock(_component_ion_menu, {
        key: 0,
        mode: "ios",
        side: "start",
        "content-id": "main-content"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, {
            class: "ion-no-border",
            style: {"background-color":"#f0f2f5"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, { color: "#F0F2F5" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, {
                    lines: "none",
                    class: "ldx-nav-header",
                    style: {"width":"100%"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, {
                        lines: "none",
                        class: "ldx-nav-header",
                        style: {"padding":"0px 10px","width":"100%"}
                      }, {
                        default: _withCtx(() => [
                          (_ctx.store.reservationData.company?.logo_url)
                            ? (_openBlock(), _createBlock(_component_ion_avatar, {
                                key: 0,
                                slot: "start"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("img", {
                                    style: {"object-fit":"contain"},
                                    src: _ctx.store.reservationData.company.logo_url,
                                    onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleImageError && _ctx.handleImageError(...args)))
                                  }, null, 40, _hoisted_1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_ion_avatar, {
                                key: 1,
                                slot: "start"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("img", {
                                    style: {"object-fit":"contain"},
                                    src: _ctx.staylioLogo
                                  }, null, 8, _hoisted_2)
                                ]),
                                _: 1
                              })),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_list_header, { style: {"padding":"0px"} }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, {
                                    style: {"padding":"0px","font-size":"clamp(0.9rem, 4.25vw - 1.5rem, 0.9rem)","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"},
                                    class: "ion-no-margin"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.store.reservationData?.company?.title ||
                                        (!_ctx.store.viewingChatWithoutReservation
                                            ? _ctx.selectedProperty?.rental_property?.name
                                            : '')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              (_ctx.store.reservationData?.company?.title && !_ctx.store.viewingChatWithoutReservation)
                                ? (_openBlock(), _createBlock(_component_ion_card_subtitle, {
                                    key: 0,
                                    style: {"font-size":"clamp(0.5rem, 4.25vw - 1.5rem, 0.65rem)","font-weight":"bold","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.selectedProperty?.rental_property?.name), 1)
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_content, { style: {"--background":"#f0f2f5"} }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list, { style: {"padding":"0px"} }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appPages, (page, index) => {
                    return (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                      "auto-hide": false,
                      key: index,
                      style: {"cursor":"pointer"}
                    }, {
                      default: _withCtx(() => [
                        (
                            (_ctx.AppFunctions.isImpersonating() && page.hideOnImpersonation == false) ||
                            !_ctx.AppFunctions.isImpersonating()
                        )
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 0,
                              lines: "none",
                              onClick: ($event: any) => (_ctx.transitionClear(page.url, index)),
                              style: _normalizeStyle([{"padding":"0px 10px"}, [
                            _ctx.isCurrentRouteActive(page.url)
                                ? 'background: rgba(var(--ion-color-secondary-rgb), 0.25)'
                                : '',
                        ]])
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  slot: "start",
                                  src: page.iconSolid,
                                  style: _normalizeStyle(`color: var(${_ctx.isCurrentRouteActive(page.url) ? page.selectedColor : page.color});`)
                                }, null, 8, ["src", "style"]),
                                _createVNode(_component_ion_label, { style: {"font-weight":"500"} }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(page.title), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["onClick", "style"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}