<template>
    <ion-header :mode="showPropertySegment && store.reservationData.tech_reservations.length > 1 ? '' : 'ios'">
        <template v-if="!imageUrl && !hideHeader">
            <ion-toolbar
                mode="ios"
                class="custom-safe-area"
                :class="
                    showPropertySegment && store.reservationData.tech_reservations.length > 1 ? 'segment-header' : ''
                "
            >
                <ion-icon
                    v-if="showCloseButton && Capacitor.getPlatform() == 'ios'"
                    slot="start"
                    @click="cancel()"
                    style="width: 30px; height: 30px; cursor: pointer"
                    :src="closeOutline"
                ></ion-icon>
                <ion-title v-if="title" style="font-weight: 700; font-size: 20px">{{ title }}</ion-title>
                <ion-icon
                    v-if="showCloseButton && Capacitor.getPlatform() != 'ios'"
                    slot="end"
                    @click="cancel()"
                    style="width: 30px; height: 30px; cursor: pointer"
                    :src="closeOutline"
                ></ion-icon>
            </ion-toolbar>
            <PropertySegment v-if="showPropertySegment && store.reservationData.tech_reservations.length > 1" />
        </template>
    </ion-header>
    <ion-header v-if="$slots.header" style="box-shadow: none; border: none">
        <ion-toolbar style="--background: white; padding: 0px !important">
            <slot name="header" />
        </ion-toolbar>
    </ion-header>
    <ion-content mode="ios">
        <ion-icon
            v-if="showCloseButton && imageUrl"
            @click="cancel()"
            style="
                width: 30px;
                height: 30px;
                cursor: pointer;
                position: fixed;
                top: env(safe-area-inset-top);
                left: env(safe-area-inset-left);
                margin: 10px 0px 0px 15px;
                z-index: 1000 !important;
                background-color: var(--ion-color-secondary);
                border-radius: 50%;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
            "
            :src="closeOutline"
            color="dark"
        ></ion-icon>
        <div :class="noClass ? '' : 'content'" :style="props.styleString">
            <div v-if="imageUrl" style="width: 100%; overflow: hidden" :style="`height: ${imageHeight}px;`">
                <div style="position: relative; padding: 0px">
                    <div
                        style="
                            font-weight: 700;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            text-align: left;
                            padding: 15px 15px;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(to top, rgba(0, 0, 0, 0.75), transparent);
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                        "
                    >
                        <ion-label style="color: white; font-size: 24px">{{ title }}</ion-label>
                        <ion-label style="color: white; font-size: 18px">{{ subtitle }}</ion-label>
                    </div>
                    <img :src="imageUrl" style="width: 100%; object-fit: cover" :style="`height: ${imageHeight}px;`" />
                </div>
            </div>
            <slot />
        </div>
    </ion-content>
    <ion-footer v-if="$slots.footer">
        <ion-toolbar
            :style="{ '--background': footerBackground }"
            style="color: black; padding: 0; padding-bottom: env(safe-area-inset-bottom) !important"
        >
            <slot name="footer" />
        </ion-toolbar>
    </ion-footer>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import { useStore } from '@/store/store';
    import { closeOutline, image } from 'ionicons/icons';
    import PropertySegment from '@/components/PropertySegment.vue';
    import { Capacitor } from '@capacitor/core';

    export default defineComponent({
        components: {
            PropertySegment,
        },
        props: {
            title: {
                type: String,
                default: '',
            },
            showPropertySegment: {
                type: Boolean,
                default: false,
            },
            showCloseButton: {
                type: Boolean,
                default: true,
            },
            styleString: {
                type: String,
                default: '',
            },
            imageUrl: {
                type: String,
                default: '',
            },
            subtitle: {
                type: String,
                default: '',
            },
            noClass: {
                type: Boolean,
                default: false,
            },
            hideHeader: {
                type: Boolean,
                default: false,
            },
            footerBackground: {
                type: String,
                required: false,
                default: 'white',
            },
        },
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();
            const imageHeight = 300;

            const cancel = async () => {
                return modalController.dismiss(null, 'cancel');
            };

            return {
                ionRouter,
                store,
                props,
                closeOutline,
                imageHeight,
                Capacitor,
                cancel,
            };
        },
    });
</script>

<style scoped>
    .custom-safe-area {
        padding-top: constant(safe-area-inset-top);
        padding-top: env(safe-area-inset-top);
        --ion-safe-area-top: 20px;
        --ion-safe-area-bottom: 20px;
    }
</style>
