<template>
    <ion-page>
        <ion-content mode="ios" :fullscreen="true">
            <div class="login-content">
                <ion-fab
                    style="
                        position: fixed;
                        top: 0;
                        left: 0;
                        margin: 1em;
                        padding-top: env(safe-area-inset-top) !important;
                    "
                >
                    <ion-fab-button @click="cancel()" color="danger">
                        <ion-icon :icon="close"></ion-icon>
                    </ion-fab-button>
                </ion-fab>
                <ion-card>
                    <ion-card-header>
                        <ion-card-title v-if="false" style="text-align: center"
                            >{{ store.appName }} Login</ion-card-title
                        >
                        <img
                            src="@/assets/nameLogo.png"
                            style="width: 100%; height: 100px; margin-bottom: 10px; object-fit: contain"
                        />
                    </ion-card-header>
                    <ion-card-content>
                        <ion-list lines="none">
                            <ion-item>
                                <ion-input
                                    label-placement="stacked"
                                    label="Email"
                                    placeholder="johndoe@example.com"
                                    v-model="email"
                                >
                                    <ion-icon slot="start" :icon="mail" aria-hidden="true"></ion-icon>
                                </ion-input>
                            </ion-item>
                            <ion-item>
                                <ion-input
                                    label-placement="stacked"
                                    label="Invoice #"
                                    placeholder="12345"
                                    type="text"
                                    v-model="reservationId"
                                >
                                    <ion-icon slot="start" :icon="lockClosed" aria-hidden="true"></ion-icon>
                                    <ion-button
                                        fill="clear"
                                        slot="end"
                                        aria-label="Show/hide"
                                        @click="basicAlert('Login Support', '', invoiceHelpBodyText)"
                                    >
                                        <ion-icon slot="icon-only" :icon="help" aria-hidden="true"></ion-icon>
                                    </ion-button>
                                </ion-input>
                            </ion-item>
                        </ion-list>

                        <div style="display: flex; justify-content: center">
                            <ion-button
                                style="
                                    width: 100%;
                                    padding: 5px 10px 0px;
                                    font-weight: bold;
                                    color: var(--ion-color-light);
                                "
                                @click="requestLogin()"
                                >Request Access Link</ion-button
                            >
                        </div>

                        <div v-if="isDevelopment" style="text-align: center; padding: 10px 0px">
                            <ion-label style="text-align: center; margin-top: 10px">or</ion-label>
                            <ion-item lines="none">
                                <ion-input
                                    label="Token"
                                    type="text"
                                    label-placement="floating"
                                    placeholder=""
                                    helper-text="Enter Generated Token"
                                    v-model="token"
                                ></ion-input>
                            </ion-item>

                            <ion-button
                                style="
                                    width: 100%;
                                    padding: 15px 25px 15px 25px;
                                    margin-top: 5px;
                                    color: var(--ion-color-light);
                                    font-weight: bold;
                                "
                                @click="loginWithToken()"
                            >
                                Token Login
                            </ion-button>
                        </div>
                    </ion-card-content>
                </ion-card>
                <div v-if="!isProduction">
                    <ion-chip v-if="!hideChip">
                        <ion-label
                            >Connection:
                            <span style="font-weight: bold">{{
                                isStaging ? 'Lodgix Staging' : connectionUrl
                            }}</span></ion-label
                        >
                        <ion-icon :icon="close" @click="hideChip = true"></ion-icon>
                    </ion-chip>
                </div>
            </div>
            <ion-fab style="position: fixed; bottom: 0; left: 0; margin: 1em">
                <ion-fab-button @click="loginAboutModal()">
                    <ion-icon :icon="help"></ion-icon>
                </ion-fab-button>
            </ion-fab>
            <ion-fab style="position: fixed; bottom: 0; right: 0; margin: 1em" v-if="false">
                <ion-fab-button @click="startScanning()">
                    <ion-icon :icon="qrCode"></ion-icon>
                </ion-fab-button>
            </ion-fab>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
    import { useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, ref } from 'vue';
    import AppFunctions from '@/services/AppFunctions';
    import { useStore } from '@/store/store';
    import { errorToast, successToast } from '@/services/ToastController';
    import AppAPI from '@/services/AppAPI';
    import { Capacitor } from '@capacitor/core';
    import { accessToken, login, refresh, updateAuthTokens, logout } from '@/services/Authentication';
    import { basicAlert } from '@/services/AlertController';
    import { mail, lockClosed, help, close, qrCode } from 'ionicons/icons';
    import router from '@/router';
    import { isDevelopment, isStaging, isProduction, connectionUrl } from '@/constants';
    import { loginAboutModal, swapReservationModal } from '@/services/ModalController';
    import { startup } from '@/services/Startup';
    //import BarcodeScanning from '@/services/BarcodeScanning';

    export default defineComponent({
        components: {},
        setup() {
            const store = useStore();
            const ionRouter = useIonRouter();
            const email = ref('');
            const reservationId = ref('');
            const token = ref('');
            const hideChip = ref(false);

            const invoiceHelpBodyText = ref(
                'The invoice number or invoice id is sometimes referred to as the reservation number or reservation id. Please check your booking confirmation for either of these numbers.'
            );

            onMounted(async () => {
                if (router.currentRoute.value.params?.token) {
                    if (AppFunctions.isSignedIn()) {
                        await logout(false);
                    }
                    token.value = router.currentRoute.value.params.token as string;
                    await loginWithToken();
                } else if (AppFunctions.isSignedIn()) {
                    ionRouter.replace('/tabs/');
                }
            });

            const cancel = () => {
                ionRouter.replace('/tabs/');
            };

            const requestLogin = async () => {
                if (email.value == '' || reservationId.value == '') {
                    errorToast('Please enter your email and stay number.');
                    return;
                }

                try {
                    const response = await AppAPI.requestMagicLink(email.value, reservationId.value);
                    if (response.data?.access && response.data?.refresh) {
                        await updateAuthTokens(response.data.access, response.data.refresh);
                        ionRouter.replace('/tabs/');
                    } else if (response.status == 204) {
                        ionRouter.replace('/tabs/');
                        basicAlert(
                            'Access Link Sent',
                            '',
                            'An authenticated access link has been sent to your email. The link will expire in 12 hours.'
                        );
                    } else {
                        errorToast('There was an issue sending the login link.');
                    }
                } catch (error: any) {
                    const message = error.response.data.error || 'There was an error sending the login link.';
                    errorToast(message);
                }
            };

            const loginWithToken = async () => {
                if (token.value == '') {
                    errorToast('Please enter token.');
                    return;
                }

                const isSuccess = await login(token.value);
                if (isSuccess) {
                    startup();
                    ionRouter.replace('/tabs/');
                } else {
                    //errorToast('There was an error logging in.');
                }
            };

            const startScanning = async () => {
                /*const access = await BarcodeScanning.checkPermissions();
                    if (!access) {
                        const gainAccess = await BarcodeScanning.requestPermissions().catch(() => false);
                        if (!gainAccess) {
                            basicAlert('Permission Denied', '', 'You must allow camera access to scan QR codes.');
                            return;
                        }
                    }
                    const barcodeData = await BarcodeScanning.scan();
                    if (barcodeData) {
                        const url = barcodeData[0]?.rawValue;
                        if (url) {
                            await AppFunctions.openLink(url);
                        }
                    }*/
            };

            return {
                ionRouter,
                store,
                Capacitor,
                email,
                token,
                reservationId,
                isDevelopment,
                mail,
                lockClosed,
                help,
                connectionUrl,
                isProduction,
                isStaging,
                close,
                hideChip,
                qrCode,
                invoiceHelpBodyText,
                startScanning,
                loginAboutModal,
                cancel,
                requestLogin,
                loginWithToken,
                basicAlert,
            };
        },
    });
</script>

<style scoped>
    .login-content {
        padding-top: env(safe-area-inset-top);
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    ion-content {
        --background: var(--background-color);
    }

    ion-card {
        width: 90%;
        max-width: 400px;
        padding: 10px 5px;
    }
</style>
