<template>
    <modal-base-layout title="Policies">
        <div
            class="section-header"
            style="margin-top: 15px; margin-right: 25px; margin-bottom: 15px"
            v-if="store.reservationData.company?.cancellation_policy"
        >
            <ion-label class="section-title">Cancellation Policy</ion-label>
            <ion-label id="cancellation_policy">{{ store.reservationData.company?.cancellation_policy }}</ion-label>
        </div>

        <div
            class="section-header"
            style="margin-top: 15px; margin-right: 25px; margin-bottom: 15px"
            v-if="store.reservationData.company?.deposit_policy"
        >
            <ion-label class="section-title">Deposit Policy</ion-label>
            <ion-label id="deposit_policy">{{ store.reservationData.company?.deposit_policy }}</ion-label>
        </div>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, ref } from 'vue';
    import { closeOutline } from 'ionicons/icons';
    import { useStore } from '@/store/store';

    export default defineComponent({
        components: {
            //
        },
        props: {},
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();

            onMounted(() => {
                const cancellation_policy = document.getElementById('cancellation_policy');
                if (cancellation_policy) {
                    cancellation_policy.innerHTML = store.reservationData.company?.cancellation_policy;
                }

                const deposit_policy = document.getElementById('deposit_policy');
                if (deposit_policy) {
                    deposit_policy.innerHTML = store.reservationData.company?.deposit_policy;
                }
            });

            return {
                ionRouter,
                closeOutline,
                store,
            };
        },
    });
</script>

<style scoped></style>
