export const isDevelopment =
    process.env.VUE_APP_URL_BASE != 'https://www.lodgix.com' &&
    process.env.VUE_APP_URL_BASE != 'https://staging.lodgix.com';

export const isStaging = process.env.VUE_APP_URL_BASE == 'https://staging.lodgix.com';

export const isProduction = process.env.VUE_APP_URL_BASE == 'https://www.lodgix.com';

export const connectionUrl = process.env.VUE_APP_URL_BASE;

export function isLocalDevelopment() {
    return (
        process.env.VUE_APP_URL_BASE != 'https://www.lodgix.com' &&
        process.env.VUE_APP_URL_BASE != 'https://staging.lodgix.com'
    );
}
