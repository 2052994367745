import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import 'leaflet/dist/leaflet.css';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

/* Theme variables */
import './theme/variables.css';

import { register } from 'swiper/element/bundle';
register();

import BaseLayout from './components/BaseLayout.vue';
import ModalBaseLayout from './components/ModalBaseLayout.vue';

import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

import { surveyPlugin } from 'survey-vue3-ui';

const app = createApp(App).use(IonicVue).use(router).use(pinia).use(surveyPlugin);

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
    faWifi,
    faTemperatureArrowUp,
    faTemperatureArrowDown,
    faShirt,
    faElevator,
    faPlugCircleBolt,
    faFire,
    faDumbbell,
    faGamepad,
    faWarehouse,
    faNetworkWired,
    faSheetPlastic,
    faSquareParking,
    faPhone,
    faBaby,
    faJugDetergent,
    faCircleCheck,
    faRotate,
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faWifi,
    faTemperatureArrowDown,
    faTemperatureArrowUp,
    faShirt,
    faElevator,
    faPlugCircleBolt,
    faFire,
    faDumbbell,
    faGamepad,
    faWarehouse,
    faNetworkWired,
    faSheetPlastic,
    faSquareParking,
    faPhone,
    faBaby,
    faJugDetergent,
    faCircleCheck,
    faRotate
);

app.component('font-awesome-icon', FontAwesomeIcon);

import {
    IonButtons,
    IonIcon,
    IonItem,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonContent,
    IonPage,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonMenuButton,
    IonThumbnail,
    IonSelect,
    IonSelectOption,
    IonList,
    IonLabel,
    IonBadge,
    IonAvatar,
    IonCardSubtitle,
    IonListHeader,
    IonSearchbar,
    IonMenu,
    IonMenuToggle,
    IonRefresher,
    IonRefresherContent,
    IonCardHeader,
    IonButton,
    IonGrid,
    IonCol,
    IonRow,
    IonTextarea,
    IonCheckbox,
    IonInput,
    IonImg,
    IonToggle,
    IonAccordion,
    IonAccordionGroup,
    IonChip,
    IonText,
    IonFooter,
    IonDatetime,
    IonDatetimeButton,
    IonPopover,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonRouterOutlet,
    IonToast,
    IonSegment,
    IonSegmentButton,
    IonFab,
    IonFabButton,
    IonFabList,
    IonActionSheet,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonSpinner,
} from '@ionic/vue';

app.component('base-layout', BaseLayout);
app.component('modal-base-layout', ModalBaseLayout);
app.component('ion-buttons', IonButtons);
app.component('ion-icon', IonIcon);
app.component('ion-item', IonItem);
app.component('ion-card', IonCard);
app.component('ion-card-content', IonCardContent);
app.component('ion-card-title', IonCardTitle);
app.component('ion-content', IonContent);
app.component('ion-page', IonPage);
app.component('ion-header', IonHeader);
app.component('ion-title', IonTitle);
app.component('ion-toolbar', IonToolbar);
app.component('ion-menu-button', IonMenuButton);
app.component('ion-thumbnail', IonThumbnail);
app.component('ion-select', IonSelect);
app.component('ion-select-option', IonSelectOption);
app.component('ion-list', IonList);
app.component('ion-label', IonLabel);
app.component('ion-badge', IonBadge);
app.component('ion-avatar', IonAvatar);
app.component('ion-card-subtitle', IonCardSubtitle);
app.component('ion-list-header', IonListHeader);
app.component('ion-searchbar', IonSearchbar);
app.component('ion-menu', IonMenu);
app.component('ion-menu-toggle', IonMenuToggle);
app.component('ion-refresher', IonRefresher);
app.component('ion-refresher-content', IonRefresherContent);
app.component('ion-card-header', IonCardHeader);
app.component('ion-button', IonButton);
app.component('ion-grid', IonGrid);
app.component('ion-col', IonCol);
app.component('ion-row', IonRow);
app.component('ion-textarea', IonTextarea);
app.component('ion-checkbox', IonCheckbox);
app.component('ion-input', IonInput);
app.component('ion-img', IonImg);
app.component('ion-toggle', IonToggle);
app.component('ion-accordion', IonAccordion);
app.component('ion-accordion-group', IonAccordionGroup);
app.component('ion-chip', IonChip);
app.component('ion-text', IonText);
app.component('ion-footer', IonFooter);
app.component('ion-datetime', IonDatetime);
app.component('ion-datetime-button', IonDatetimeButton);
app.component('ion-popover', IonPopover);
app.component('ion-tab-bar', IonTabBar);
app.component('ion-tab-button', IonTabButton);
app.component('ion-tabs', IonTabs);
app.component('ion-router-outlet', IonRouterOutlet);
app.component('ion-toast', IonToast);
app.component('ion-segment', IonSegment);
app.component('ion-segment-button', IonSegmentButton);
app.component('ion-fab', IonFab);
app.component('ion-fab-button', IonFabButton);
app.component('ion-fab-list', IonFabList);
app.component('ion-action-sheet', IonActionSheet);
app.component('ion-item-sliding', IonItemSliding);
app.component('ion-item-options', IonItemOptions);
app.component('ion-item-option', IonItemOption);
app.component('ion-spinner', IonSpinner);

app.config.compilerOptions.isCustomElement = (tag) => tag === 'swiper-slide';
app.config.compilerOptions.isCustomElement = (tag) => tag === 'swiper-container';
app.config.compilerOptions.isCustomElement = (tag) => tag === 'inbox-component';

defineCustomElements(window);

router.isReady().then(() => {
    app.mount('#app');
});
