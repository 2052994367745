<template>
    <base-layout style="overflow: hidden !important" isChat>
        <LoginWarning v-if="!accessToken"></LoginWarning>
        <template v-else>
            <template v-if="isInView">
                <div
                    style="overflow: hidden; position: fixed; width: 100%; text-align: left"
                    :style="{ height: componentHeight }"
                >
                    <inbox-component
                        v-if="isLoaded"
                        :deviceType="deviceType"
                        :headerHeight="headerHeight"
                        :token="accessToken"
                        :mobileHost="mobileHost"
                        :chatUserId="chatUserId"
                        :deviceToken="websocketToken"
                        :websocket-url="websocketUrlBase"
                        :subscriberId="store.user.subscriber_id"
                        id="inbox"
                        @toast="toastHandler"
                        :toast-event="toastEvent"
                        :permissions="JSON.stringify('')"
                        :isApp="true"
                        :userType="'GUEST'"
                        :footerHeight="footerHeight"
                        @routerUpdate="updateRouter"
                        :scrollToBottom="store.scrollToBottomCounter"
                        :subscriberActive="store.reservationData?.subscriber_active"
                        @currentConversationSubject="updateSubject"
                    />
                </div>
            </template>
        </template>
    </base-layout>
</template>

<script lang="ts">
    import LoginWarning from '@/components/LoginWarning.vue';
    import { useIonRouter } from '@ionic/vue';
    import { computed, defineComponent, watch } from 'vue';
    import { useStore } from '@/store/store';
    import { accessToken } from '@/services/Authentication';
    import { Capacitor } from '@capacitor/core';
    import { errorToast, infoActionToast, successToast } from '@/services/ToastController';
    import AppAPI from '@/services/AppAPI';
    import { Keyboard } from '@capacitor/keyboard';
    import router from '@/router';
    import AppFunctions from '@/services/AppFunctions';
    import { showLoading, hideLoading } from '@/services/LoadingController';
    import { isLocalDevelopment } from '@/constants';
    import { basicAlert } from '@/services/AlertController';

    export default defineComponent({
        components: { LoginWarning },
        setup() {
            const store = useStore();
            const ionRouter = useIonRouter();
            const deviceType = computed(() => {
                return Capacitor.getPlatform();
            });
            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            const isInView = computed(() => {
                return router.currentRoute.value.path.includes('/tabs/messages');
            });

            return {
                ionRouter,
                isInView,
                deviceType,
                store,
                accessToken,
                selectedProperty,
            };
        },

        methods: {
            emitKeyboard(e: any) {
                console.log('keyboard', e);
                this.componentHeight = '100%';
            },

            load() {
                this.$nextTick(function () {
                    setTimeout(() => {
                        const element = document.getElementById('inbox');
                        const rect = element?.getBoundingClientRect();
                        this.headerHeight = rect?.top || 0;
                        this.footerHeight = document.getElementById('footer')?.clientHeight || 0;
                    }, 100);
                });
            },
            async getData() {
                const user = (await AppAPI.getUser()).data;
                if (user) {
                    this.store.user = user;
                }
            },
            async toastHandler(data: any) {
                if (data.detail[0].mode === 'success') {
                    await successToast(data.detail[0].message);
                } else if (data.detail[0].mode === 'error') {
                    await errorToast(data.detail[0].message);
                } else {
                    await infoActionToast(data.detail[0].message, 'View', () => {
                        this.toastEvent = data.detail[0].conversationId;
                    });
                }
            },
            async updateRouter(data: any) {
                if (data.detail[0] === '/' || data.detail[0].includes('/conversation/')) {
                    this.ionRouter.replace('/tabs/messages' + data.detail[0]);
                }
            },
            async updateSubject(data: any) {
                this.store.currentConversationSubject = data.detail[0];
            },
        },

        async mounted() {
            if (Capacitor.getPlatform() != 'web') {
                Keyboard.addListener('keyboardWillShow', (info) => {
                    this.footerHeight = 0;
                });

                Keyboard.addListener('keyboardDidHide', async () => {
                    this.footerHeight = document.getElementById('footer')?.clientHeight || 0;
                    if (this.footerHeight == 0) {
                        setTimeout(() => {
                            this.footerHeight = document.getElementById('footer')?.clientHeight || 0;
                        }, 250);
                    }
                });
            }
            if (this.store.reservationData?.conversation_id) {
                if (
                    router.currentRoute.value.params.pathMatch[1]?.toString() !=
                        this.store.reservationData.conversation_id ||
                    this.store.viewingChatWithoutReservation
                ) {
                    try {
                        let id = '';
                        if (this.store.allReservations.length <= 0) {
                            const loadingRes = await showLoading('Loading...');
                            try {
                                await AppFunctions.refreshReservationList();
                            } catch (e) {
                                console.log(e);
                            } finally {
                                hideLoading(loadingRes);
                            }
                        }
                        for (let i = 0; i < this.store.allReservations.length; i++) {
                            if (
                                this.store.allReservations[i].conversation_id ==
                                router.currentRoute.value.params.pathMatch[1].toString()
                            ) {
                                id = this.store.allReservations[i].id;
                                break;
                            }
                        }
                        if (id) {
                            await this.store.changeSelectedReservationId(id);
                            const reservation = (await AppAPI.getReservationData(this.store.selectedReservationId))
                                .data;
                            this.store.reservationData = reservation;
                            this.store.selectedTechReservationId = reservation.tech_reservations[0].id;
                            await AppFunctions.getHostedForms();
                            this.store.viewingChatWithoutReservation = false;
                        } else {
                            if (!this.store.viewingChatWithoutReservation) {
                                //alert('Alert: There is no invoice found for this conversation.')
                            }
                            this.store.noReservationChatConversationId =
                                router.currentRoute.value.params.pathMatch[1]?.toString();
                            this.store.viewingChatWithoutReservation = true;
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
            await this.getData();
            if (AppFunctions.isImpersonating()) {
                basicAlert('Alert', '', 'Inbox is not available for your account.');
                router.replace('/tabs/');
            }
            this.load();
            if (this.store.user.chat_user_id) {
                if (process.env.VUE_APP_INBOX_LIVE_RELOAD === 'true' && isLocalDevelopment()) {
                    let link = document.createElement('link');
                    link.rel = 'stylesheet';
                    link.href = 'http://localhost:5173/src/styles/main.css';
                    document.head.appendChild(link);

                    link = document.createElement('link');
                    link.rel = 'stylesheet';
                    link.href = `unified-inbox/assets/fonts/lato/lato.css`;
                    document.head.appendChild(link);

                    const script = document.createElement('script');
                    script.type = 'module';
                    script.src = 'http://localhost:5173/src/main.ce.ts';
                    document.body.appendChild(script);
                } else {
                    let link = document.createElement('link');
                    link.rel = 'stylesheet';
                    link.href = 'unified-inbox/style.css';
                    document.head.appendChild(link);

                    link = document.createElement('link');
                    link.rel = 'stylesheet';
                    link.href = `unified-inbox/assets/fonts/lato/lato.css`;
                    document.head.appendChild(link);

                    const script = document.createElement('script');
                    script.type = 'module';
                    script.src = `unified-inbox/inbox-component.js`;
                    script.onerror = () => {
                        console.error('Failed to load inbox-component script');
                    };
                    document.body.appendChild(script);
                }
                await new Promise((resolve) => setTimeout(resolve, 500));
                this.isLoaded = true;
                this.load();
            }
        },
        data() {
            return {
                headerHeight: 0,
                isLoaded: false,
                componentHeight: '100%',
                toastEvent: null,
                footerHeight: 0,
            };
        },
        computed: {
            mobileHost() {
                return process.env.VUE_APP_URL_BASE;
            },
            websocketUrlBase() {
                let tmp = process.env.VUE_APP_URL_BASE;
                tmp = tmp.replace('https://', 'wss://');
                tmp = tmp + this.store.user.websocket_url;
                return tmp;
            },
            chatUserId() {
                return this.store.user.chat_user_id;
            },
            websocketToken() {
                return this.store.user.websocket_token;
            },
        },
    });
</script>

<style scoped></style>
