<template>
    <svg
        fill="currentColor"
        stroke="currentColor"
        viewBox="0 0 1024 1024"
        height="25"
        width="25"
        version="1.1"
        stroke-width="30"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M921.6 540.444444c-17.066667 0-28.444444-11.377778-28.444444-28.444444 0-221.866667-176.355556-398.222222-398.222223-398.222222-11.377778 0-28.444444-11.377778-28.444444-28.444445s17.066667-28.444444 34.133333-28.444444c250.311111 0 455.111111 204.8 455.111111 455.111111 0 17.066667-17.066667 28.444444-34.133333 28.444444zM523.377778 227.555556c-11.377778 0-22.755556 11.377778-22.755556 28.444444V512H295.822222c-11.377778 0-22.755556 11.377778-22.755555 28.444444s11.377778 28.444444 22.755555 28.444445h238.933334c5.688889-5.688889 11.377778-5.688889 17.066666-17.066667V256c5.688889-17.066667-11.377778-28.444444-28.444444-28.444444z"
        />
        <path
            d="M500.622222 910.222222c-221.866667 0-398.222222-176.355556-398.222222-398.222222 0-119.466667 51.2-227.555556 142.222222-307.2V284.444444c0 17.066667 11.377778 28.444444 28.444445 28.444445s22.755556-11.377778 22.755555-28.444445V170.666667c0-34.133333-22.755556-56.888889-56.888889-56.888889h-113.777777c-11.377778 0-22.755556 11.377778-22.755556 28.444444s11.377778 28.444444 22.755556 28.444445h68.266666C96.711111 256 45.511111 381.155556 45.511111 512c0 250.311111 204.8 455.111111 455.111111 455.111111 17.066667 0 28.444444-11.377778 28.444445-28.444444S512 910.222222 500.622222 910.222222zM694.044444 932.977778c-17.066667 0-28.444444-5.688889-39.822222-17.066667l-147.911111-130.844444c-11.377778-11.377778-11.377778-28.444444 0-39.822223 11.377778-11.377778 28.444444-11.377778 39.822222 0l147.911111 130.844445 204.8-238.933333c11.377778-11.377778 28.444444-11.377778 39.822223-5.688889 11.377778 11.377778 11.377778 28.444444 5.688889 39.822222L739.555556 915.911111c-11.377778 11.377778-28.444444 17.066667-45.511112 17.066667z"
        />
    </svg>
</template>
