import { Capacitor } from '@capacitor/core';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { advancedAlert } from './AlertController';
import { useStore } from './../store/store';

export async function checkForAppUpdate() {
    const store = useStore();
    if (Capacitor.getPlatform() === 'web') {
        return;
    }

    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
        return;
    } else {
        if (!store.isBetaUser) {
            return;
        }
    }

    const title = 'Update Available';
    const message = 'A new version of Staylio is available. Please update to the latest version.';
    const buttons = [
        {
            text: 'Go to Store',
            handler: async () => {
                try {
                    await AppUpdate.openAppStore();
                } catch (error) {
                    console.error(error);
                }
            },
        },
    ];

    const info = await AppUpdate.getAppUpdateInfo();
    if (Capacitor.getPlatform() === 'android') {
        if (info.currentVersionCode !== info.availableVersionCode) {
            await advancedAlert(title, '', message, buttons, true);
        }
    } else if (Capacitor.getPlatform() === 'ios') {
        if (info.currentVersionName !== info.availableVersionName) {
            await advancedAlert(title, '', message, buttons, true);
        }
    }
}
