import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { style: {"display":"flex"} }
const _hoisted_2 = { style: {"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"} }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  style: {"background-color":"rgb(255, 187, 51)","border-bottom":"1px solid lightgrey","text-align":"center"}
}
const _hoisted_6 = {
  key: 1,
  style: {"background-color":"rgb(255, 187, 51)","border-bottom":"1px solid lightgrey","text-align":"center"}
}
const _hoisted_7 = { style: {"font-weight":"bold"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { id: "main-content" }, {
    default: _withCtx(() => [
      (_ctx.accessToken)
        ? (_openBlock(), _createBlock(_component_ion_header, {
            key: 0,
            mode: "ios"
          }, {
            default: _withCtx(() => [
              (!_ctx.noHeader)
                ? (_openBlock(), _createBlock(_component_ion_toolbar, {
                    key: 0,
                    mode: "md",
                    class: "tabs-header"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        (!_ctx.hideSideMenu)
                          ? (_openBlock(), _createBlock(_component_ion_buttons, {
                              key: 0,
                              slot: "start"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_menu_button)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_2, [
                          (_ctx.accessToken && !_ctx.noTitle && !_ctx.store.viewingChatWithoutReservation)
                            ? (_openBlock(), _createBlock(_component_ion_title, {
                                key: 0,
                                style: {"font-weight":"700","font-size":"20px","padding":"0px","margin":"0px"}
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.pageTitle || _ctx.selectedProperty?.rental_property?.name), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.accessToken && !_ctx.noTitle && _ctx.store.selectedReservationId)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                (!_ctx.store.viewingChatWithoutReservation)
                                  ? (_openBlock(), _createBlock(_component_ion_label, {
                                      key: 0,
                                      style: {"font-weight":"700","font-size":"12px","padding":"0px","margin":"0px"}
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Invoice #" + _toDisplayString(_ctx.store.selectedReservationId), 1)
                                      ]),
                                      _: 1
                                    }))
                                  : (_openBlock(), _createElementBlock("div", {
                                      key: 1,
                                      innerHTML: _ctx.formatSubject,
                                      style: {"font-weight":"700","font-size":"14px","padding":"0","margin":"0"}
                                    }, null, 8, _hoisted_4))
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      (!_ctx.isProduction && _ctx.router && false)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createVNode(_component_ion_label, { style: {"color":"black"} }, {
                              default: _withCtx(() => [
                                _createTextVNode("URL: " + _toDisplayString(_ctx.router?.currentRoute?.value?.fullPath), 1)
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true),
                      (!_ctx.isProduction)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createVNode(_component_ion_label, { style: {"color":"black"} }, {
                              default: _withCtx(() => [
                                _createTextVNode("Connection: "),
                                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.isStaging ? 'Lodgix Staging' : _ctx.connectionUrl), 1)
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_content, {
        class: _normalizeClass([_ctx.isChat ? 'messages' : '']),
        mode: "ios",
        fullscreen: true
      }, {
        default: _withCtx(() => [
          (_ctx.noHeader)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                style: _normalizeStyle([{"position":"fixed","top":"0","height":"env(safe-area-inset-top)","width":"100%","z-index":"1000 !important"}, [!_ctx.isChat ? 'background-color: var(--background-color);' : 'background-color: #fff']])
              }, null, 4))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            style: _normalizeStyle([_ctx.noHeader ? 'padding-top: env(safe-area-inset-top);' : ''])
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 4)
        ]),
        _: 3
      }, 8, ["class"])
    ]),
    _: 3
  }))
}