<template>
    <base-layout>
        <LoginWarning v-if="!accessToken"></LoginWarning>
        <div class="content" v-else>
            <UserInformation></UserInformation>
            <SettingsComponent></SettingsComponent>
        </div>
    </base-layout>
</template>

<script lang="ts">
    import LoginWarning from '@/components/LoginWarning.vue';
    import { useIonRouter } from '@ionic/vue';
    import { computed, defineComponent } from 'vue';
    import { useStore } from '@/store/store';
    import SettingsComponent from '@/components/Settings/SettingsComponent.vue';
    import UserInformation from '@/components/Settings/UserInformation.vue';
    import { accessToken } from '@/services/Authentication';
    import AppFunctions from '@/services/AppFunctions';

    export default defineComponent({
        components: { LoginWarning, SettingsComponent, UserInformation },
        setup() {
            const store = useStore();
            const ionRouter = useIonRouter();

            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            return {
                ionRouter,
                store,
                accessToken,
                selectedProperty,
            };
        },
    });
</script>

<style scoped></style>
