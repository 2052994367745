<template>
    <div>
        <ion-segment mode="md" class="header" v-model="store.selectedTechReservationId" :scrollable="true">
            <template v-for="(item, index) in store.reservationData.tech_reservations" :key="index">
                <ion-segment-button
                    class="header"
                    :style="[store.reservationData?.tech_reservations?.length <= 1 ? '--indicator-height: 0px;' : '']"
                    :value="item.id"
                >
                    <ion-label style="color: var(--header-text-color)">{{
                        truncateName(item.rental_property.name)
                    }}</ion-label>
                </ion-segment-button>
            </template>
        </ion-segment>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent } from 'vue';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';

    export default defineComponent({
        components: {
            //
        },
        setup() {
            const store = useStore();

            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            const truncateName = (name: string) => {
                if (name.length > 35) {
                    return name.substring(0, 35) + '...';
                } else {
                    return name;
                }
            };

            return {
                store,
                selectedProperty,
                truncateName,
            };
        },
    });
</script>
