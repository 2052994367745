import AppFunctions from '@/services/AppFunctions';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { defineStore } from 'pinia';

export interface pushNotificationListType {
    id: string;
    title: string;
    message: string;
    read_status: string;
    created: string;
    archived: boolean;
}

export const useStore = defineStore('useStore', {
    state: () => ({
        appName: 'Staylio',
        user: {} as any,
        selectedReservationId: '',
        selectedTechReservationId: '',
        reservationData: {} as any,
        allReservations: [] as any,
        pushNotificationToken: '',
        pushNotificationList: [] as pushNotificationListType[] | undefined,
        reviewStatus: '',
        hostedForms: [] as any,
        openInMapsAddress: '',
        hasPushNotificationReminderSent: false,
        pushNotificationReminderHidden: false,
        scrollToBottomCounter: 0,
        viewingChatWithoutReservation: false,
        noReservationChatConversationId: '',
        currentConversationSubject: '',
        isBetaUser: false,
        showArchived: false,
    }),

    actions: {
        async changeSelectedReservationId(newValue: any) {
            await SecureStoragePlugin.set({ key: 'selectedResId', value: newValue.toString() }).catch((error) => {
                console.log(error);
            });
            this.selectedReservationId = newValue;
            if (this.selectedReservationId) {
                this.noReservationChatConversationId = '';
                this.viewingChatWithoutReservation = false;
                await AppFunctions.updatePushNotificationList();
            }
        },
        async clear() {
            await SecureStoragePlugin.clear();
            this.user = {};
            this.selectedReservationId = '';
            this.selectedTechReservationId = '';
            this.reservationData = {};
            this.allReservations = [];
            this.pushNotificationToken = '';
        },
        async updateReviewStatus(newValue: string) {
            SecureStoragePlugin.set({ key: 'reviewStatus', value: newValue });
            this.reviewStatus = newValue;
        },
        async updatepushNotificationReminderHidden(newValue: boolean) {
            if (newValue) {
                SecureStoragePlugin.set({ key: 'pushNotificationReminderState', value: 'true' });
                this.pushNotificationReminderHidden = true;
            } else {
                SecureStoragePlugin.set({ key: 'pushNotificationReminderState', value: 'false' });
                this.pushNotificationReminderHidden = false;
            }
        },
    },

    persist: {
        storage: sessionStorage,
    },
});
