import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1567e50e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "section-header",
  style: {"margin-top":"15px","margin-right":"25px","margin-bottom":"15px"}
}
const _hoisted_4 = {
  key: 1,
  class: "section-header",
  style: {"margin-top":"15px","margin-right":"25px","margin-bottom":"15px"}
}
const _hoisted_5 = {
  key: 2,
  class: "section-header",
  style: {"margin-top":"15px","margin-right":"25px","margin-bottom":"15px"}
}
const _hoisted_6 = {
  key: 3,
  class: "section-header",
  style: {"margin-top":"15px","margin-right":"25px","margin-bottom":"15px"}
}
const _hoisted_7 = {
  key: 4,
  class: "section-header",
  style: {"margin-top":"15px","margin-right":"25px","margin-bottom":"15px"}
}
const _hoisted_8 = {
  key: 5,
  class: "section-header",
  style: {"margin-top":"15px","margin-right":"25px","margin-bottom":"15px"}
}
const _hoisted_9 = {
  key: 1,
  class: "bottom-button-fixed"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, { title: "Contact Us" }, {
    default: _withCtx(() => [
      (_ctx.company)
        ? (_openBlock(), _createBlock(_component_ion_card, {
            key: 0,
            style: {"margin-bottom":"125px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_card_header, { style: {"justify-content":"center","align-items":"center","display":"flex","text-align":"center"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, {
                    class: "section-title",
                    style: {"font-size":"25px"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.company.title), 1)
                    ]),
                    _: 1
                  }),
                  (_ctx.company.logo_url)
                    ? (_openBlock(), _createBlock(_component_ion_avatar, {
                        key: 0,
                        style: {"width":"150px","height":"150px","margin":"15px 0 25px"}
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            style: {"object-fit":"contain"},
                            src: _ctx.company.logo_url,
                            onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleImageError && _ctx.handleImageError(...args)))
                          }, null, 40, _hoisted_1)
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_ion_avatar, {
                        key: 1,
                        slot: "start"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            style: {"object-fit":"contain"},
                            src: _ctx.staylioLogo
                          }, null, 8, _hoisted_2)
                        ]),
                        _: 1
                      }))
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card_content, null, {
                default: _withCtx(() => [
                  (_ctx.company.public_phone)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_ion_label, { class: "section-title" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Phone")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.company.public_phone), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_icon, {
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.phoneCall(_ctx.company.public_phone))),
                              icon: _ctx.call,
                              slot: "end"
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.company.public_email)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_ion_label, { class: "section-title" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Email")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.company.public_email), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_icon, {
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.sendEmail(_ctx.company.public_email))),
                              icon: _ctx.mail,
                              slot: "end"
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.company.public_website)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_ion_label, { class: "section-title" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Website")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.company.public_website), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_icon, {
                              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openLink(_ctx.company.public_website))),
                              icon: _ctx.exit,
                              slot: "end"
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.company.address_obj)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_ion_label, { class: "section-title" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Address")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.company.address_obj.address1 + ' ' + _ctx.company.address_obj.short_name), 1)
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.company.deposit_policy)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_component_ion_label, { class: "section-title" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Deposit Policy")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_label, { id: "deposit_policy" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.company.deposit_policy), 1)
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.company.cancellation_policy)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(_component_ion_label, { class: "section-title" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Cancellation Policy")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_label, { id: "cancellation_policy" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.company.cancellation_policy), 1)
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.AppFunctions.isImpersonating())
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_ion_button, {
              style: {"width":"175px","font-weight":"bold"},
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openChat()))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Chat")
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}