const whitelistDomains = [
    '.google.com',
    '.facebook.com',
    '.page',
    '.dropbox.com',
    'onedrive.live.com',
    'staylio.com',
    'lodgix.com',
];

export function checkUrl(url: string) {
    const isWhitelisted = whitelistDomains.some((domain) => url.includes(domain));
    return isWhitelisted;
}

export function isHTTPS(url: string) {
    return url.startsWith('https://');
}
