<template>
    <modal-base-layout title="Uncompleted Forms">
        <div
            v-if="store.hostedForms.length < 1"
            style="margin: 30px; display: flex; flex-direction: column; justify-content: center; align-items: center"
        >
            <ion-icon color="primary" :icon="documentText" style="height: 80px; width: 50px"></ion-icon>
            <ion-label>There are currently no uncompleted forms. All forms have been completed.</ion-label>
        </div>

        <template v-for="(item, index) in store.hostedForms" :key="index">
            <ion-card @click="hostedFormsSurveyModal(item)" style="margin: 15px; cursor: pointer">
                <ion-card-content style="display: flex; justify-content: space-between; margin: 10px">
                    <div style="display: flex; align-items: center">
                        <ion-icon :icon="documentTextOutline" style="height: 45px; width: 30px"></ion-icon>
                        <ion-label style="font-weight: bold; font-size: 18px; display: flex; align-items: center">{{
                            item.hosted_form.title
                        }}</ion-label>
                    </div>
                    <ion-icon :icon="chevronForward" style="height: 45px; width: 30px"></ion-icon>
                </ion-card-content>
            </ion-card>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, ref } from 'vue';
    import {
        bicycle,
        closeOutline,
        home,
        restaurant,
        trailSign,
        chevronForward,
        documentTextOutline,
        documentText,
    } from 'ionicons/icons';
    import { useStore } from '@/store/store';
    import { hostedFormsSurveyModal } from '@/services/ModalController';

    export default defineComponent({
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();

            onMounted(() => {
                //
            });

            return {
                ionRouter,
                closeOutline,
                store,
                bicycle,
                chevronForward,
                restaurant,
                trailSign,
                home,
                documentTextOutline,
                documentText,
                hostedFormsSurveyModal,
            };
        },
    });
</script>

<style scoped>
    ion-card {
        margin: 2px;
    }
    ion-item {
        --padding-top: 5px;
        --padding-bottom: 5px;
    }
</style>
