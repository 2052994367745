<template>
    <div v-if="selectedProperty.door_code">
        <ion-card>
            <ion-card-header>
                <ion-label class="section-title">Door Code</ion-label>
            </ion-card-header>
            <ion-card-content>
                <ion-item lines="none">
                    <ion-input readonly :value="selectedProperty.door_code || 'N/A'"></ion-input>
                    <ion-icon
                        slot="end"
                        :icon="copyOutline"
                        @click="copyDoorCode(selectedProperty.door_code)"
                    ></ion-icon>
                </ion-item>
            </ion-card-content>
        </ion-card>
    </div>
</template>

<script lang="ts">
    import { defineComponent, computed } from 'vue';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';
    import { infoToast } from '@/services/ToastController';
    import { copyOutline } from 'ionicons/icons';

    export default defineComponent({
        setup() {
            const store = useStore();

            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            const copyDoorCode = async (code: string) => {
                await AppFunctions.writeToClipboard(code);
                infoToast('Door Code Copied');
            };

            return {
                selectedProperty,
                store,
                copyOutline,
                copyDoorCode,
            };
        },
    });
</script>
