import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_ctx.selectedProperty.door_code)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ion_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_header, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { class: "section-title" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Door Code")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card_content, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_item, { lines: "none" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_input, {
                      readonly: "",
                      value: _ctx.selectedProperty.door_code || 'N/A'
                    }, null, 8, ["value"]),
                    _createVNode(_component_ion_icon, {
                      slot: "end",
                      icon: _ctx.copyOutline,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyDoorCode(_ctx.selectedProperty.door_code)))
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}