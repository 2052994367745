import { actionSheetController } from '@ionic/vue';
import { hapticsImpactHeavy } from './HapticsController';
import { useStore } from '@/store/store';

const basicActionSheet = async (title: string, buttons: any) => {
    hapticsImpactHeavy();
    title = title == '' ? 'Actions' : title;
    const actionSheet = await actionSheetController.create({
        header: title,
        cssClass: 'actionMenuClass',
        buttons: buttons,
    });
    await actionSheet.present();
};

const advancedActionSheet = async (title: string, buttons: any, address?: string) => {
    hapticsImpactHeavy();
    const store = useStore();
    store.openInMapsAddress = address || '';
    title = title == '' ? 'Actions' : title;
    const actionSheet = await actionSheetController.create({
        header: title,
        cssClass: 'actionMenuClass',
        buttons: buttons,
    });
    await actionSheet.present();

    const { data, role } = await actionSheet.onDidDismiss();
    store.openInMapsAddress = '';
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export { basicActionSheet, advancedActionSheet };
