<template>
    <ion-card v-if="selectedProperty.rental_property.check_in_options.instruction">
        <ion-card-header>
            <ion-label class="section-title">Occupancy Details</ion-label>
        </ion-card-header>
        <ion-card-content>
            <ion-list lines="none">
                <ion-item>
                    <ion-label>Adults</ion-label>
                    <ion-label slot="end" style="text-align: right">{{ selectedProperty.guests }}</ion-label>
                </ion-item>
                <ion-item>
                    <ion-label>Children</ion-label>
                    <ion-label slot="end" style="text-align: right">{{ selectedProperty.children }}</ion-label>
                </ion-item>
                <ion-item lines="none">
                    <ion-label>Pets</ion-label>
                    <ion-label slot="end" style="text-align: right">{{ selectedProperty.number_of_pets }}</ion-label>
                </ion-item>
            </ion-list>
        </ion-card-content>
    </ion-card>
</template>

<script lang="ts">
    import { defineComponent, computed } from 'vue';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';

    export default defineComponent({
        setup() {
            const store = useStore();

            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            return {
                selectedProperty,
                store,
                AppFunctions,
            };
        },
    });
</script>
