<template>
    <ion-card v-if="Capacitor.getPlatform() != 'web'">
        <ion-card-header>
            <ion-label class="section-title">Settings</ion-label>
        </ion-card-header>
        <ion-card-content>
            <ion-list lines="full">
                <ion-item
                    style="cursor: pointer"
                    v-if="showEnablePushNotificationSetting"
                    @click="pushNotificationReminderModal"
                >
                    <ion-label style="margin-left: 5px">Enable Push Notifications</ion-label>
                    <ion-icon :icon="chevronForward" color="primary"></ion-icon>
                </ion-item>
                <ion-item style="cursor: pointer" @click="openDeviceSettings">
                    <ion-label style="margin-left: 5px">App Device Settings</ion-label>
                    <ion-icon :icon="chevronForward" color="primary"></ion-icon>
                </ion-item>
            </ion-list>
        </ion-card-content>
    </ion-card>

    <ion-card>
        <ion-card-header>
            <ion-label class="section-title">About</ion-label>
        </ion-card-header>
        <ion-card-content>
            <ion-list lines="full">
                <ion-item
                    style="cursor: pointer"
                    @click="AppFunctions.openLink('https://www.lodgix.com/privacy-policy/')"
                >
                    <ion-label style="margin-left: 5px">Privacy Policy</ion-label>
                    <ion-icon :icon="chevronForward" color="primary"></ion-icon>
                </ion-item>
                <ion-item
                    style="cursor: pointer"
                    @click="AppFunctions.openLink('https://www.lodgix.com/terms-of-service/')"
                >
                    <ion-label>Terms of Service</ion-label>
                    <ion-icon :icon="chevronForward" color="primary"></ion-icon>
                </ion-item>
                <ion-item lines="none">
                    <ion-label style="margin-left: 5px">App Version</ion-label>
                    <ion-label slot="end" style="text-align: end">{{ appVersion }}{{ buildNumber }}</ion-label>
                </ion-item>
                <div v-if="isDevelopment && false">
                    <ion-label>Access Token</ion-label>
                    <p style="text-align: start; padding: 5px">{{ accessToken }}</p>
                    <ion-label>Refresh Token</ion-label>
                    <p style="text-align: start; padding: 5px">{{ refreshToken }}</p>

                    <ion-button @click="refresh()">Refresh Tokens</ion-button>
                    <ion-button @click="changeAccessToken()">Change Access Token</ion-button>
                    <ion-button @click="changeRefreshToken()">Change Refresh Token</ion-button>
                    <ion-button @click="clearTokens()">Clear Tokens</ion-button>

                    <ion-button @click="fcmTest()">FCM Test</ion-button>
                    <ion-label>{{ result }}</ion-label>

                    <ion-button @click="getDeviceInfo()">Get device info</ion-button>
                    <ion-label>{{ deviceInfo }}</ion-label>
                </div>
            </ion-list>
        </ion-card-content>
    </ion-card>

    <ion-button @click="logout()" color="danger" style="width: 250px; font-weight: bold">Sign Out</ion-button>

    <div style="margin: 50px" />
</template>

<script lang="ts">
    import { useIonRouter } from '@ionic/vue';
    import { computed, defineComponent, onMounted, ref } from 'vue';
    import { useStore } from '@/store/store';
    import { accessToken, logout, refresh, refreshToken, updateAuthTokens } from '@/services/Authentication';
    import { chevronForward } from 'ionicons/icons';
    import AppFunctions from '@/services/AppFunctions';
    import AppAPI from '@/services/AppAPI';
    import { isDevelopment } from '@/constants';
    import { Device } from '@capacitor/device';
    import { Capacitor } from '@capacitor/core';
    import { pushNotificationReminderModal } from '@/services/ModalController';
    import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
    import { PushNotifications } from '@capacitor/push-notifications';

    export default defineComponent({
        components: {},
        setup() {
            const store = useStore();
            const ionRouter = useIonRouter();
            const appVersion = ref(process.env.VUE_APP_VERSION);
            const result = ref('');
            const deviceInfo = ref('');
            const showEnablePushNotificationSetting = ref(false);

            const buildNumber = computed(() => {
                const os = Capacitor.getPlatform();
                if (os == 'android') {
                    return ' · Build ' + process.env.VUE_APP_BUILD_ANDROID;
                } else if (os == 'ios') {
                    return ' · Build ' + process.env.VUE_APP_BUILD_IOS;
                } else {
                    return ' · Build WEB';
                }
            });

            onMounted(async () => {
                if (Capacitor.getPlatform() != 'web') {
                    const response = await PushNotifications.checkPermissions();
                    if (response.receive === 'granted') {
                        showEnablePushNotificationSetting.value = false;
                    } else {
                        showEnablePushNotificationSetting.value = true;
                    }
                }
            });

            const changeAccessToken = async () => {
                await updateAuthTokens('access', refreshToken.value);
            };
            const changeRefreshToken = async () => {
                await updateAuthTokens(accessToken.value, 'refreshToken');
            };

            const clearTokens = async () => {
                await updateAuthTokens('', '');
            };

            const getDeviceInfo = async () => {
                const device = await AppAPI.getDeviceById(store.pushNotificationToken);
                result.value = JSON.stringify(device);
                deviceInfo.value = JSON.stringify(await Device.getId());
            };

            const fcmTest = async () => {
                const test = await AppAPI.deactivateDevice(store.pushNotificationToken);
                const device = await AppAPI.getDeviceById(store.pushNotificationToken);
                result.value = JSON.stringify(device);
            };

            const openDeviceSettings = () => {
                NativeSettings.open({
                    optionAndroid: AndroidSettings.ApplicationDetails,
                    optionIOS: IOSSettings.App,
                });
            };

            return {
                ionRouter,
                store,
                accessToken,
                refreshToken,
                appVersion,
                AppFunctions,
                chevronForward,
                result,
                isDevelopment,
                deviceInfo,
                getDeviceInfo,
                fcmTest,
                refresh,
                logout,
                changeAccessToken,
                changeRefreshToken,
                clearTokens,
                pushNotificationReminderModal,
                openDeviceSettings,
                showEnablePushNotificationSetting,
                buildNumber,
                Capacitor,
            };
        },
    });
</script>

<style scoped></style>
