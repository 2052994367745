import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0eea8ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"margin":"30px","display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}
}
const _hoisted_2 = { style: {"display":"flex","align-items":"center"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, { title: "Uncompleted Forms" }, {
    default: _withCtx(() => [
      (_ctx.store.hostedForms.length < 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_ion_icon, {
              color: "primary",
              icon: _ctx.documentText,
              style: {"height":"80px","width":"50px"}
            }, null, 8, ["icon"]),
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode("There are currently no uncompleted forms. All forms have been completed.")
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.hostedForms, (item, index) => {
        return (_openBlock(), _createBlock(_component_ion_card, {
          key: index,
          onClick: ($event: any) => (_ctx.hostedFormsSurveyModal(item)),
          style: {"margin":"15px","cursor":"pointer"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_content, { style: {"display":"flex","justify-content":"space-between","margin":"10px"} }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.documentTextOutline,
                    style: {"height":"45px","width":"30px"}
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, { style: {"font-weight":"bold","font-size":"18px","display":"flex","align-items":"center"} }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.hosted_form.title), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _createVNode(_component_ion_icon, {
                  icon: _ctx.chevronForward,
                  style: {"height":"45px","width":"30px"}
                }, null, 8, ["icon"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}