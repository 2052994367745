import { hapticsImpactHeavy } from '@/services/HapticsController';
import { alertController } from '@ionic/vue';

const basicAlert = async (title: string, subTitle: string, message: string, hideHaptic = false) => {
    if (!hideHaptic) {
        hapticsImpactHeavy();
    }
    title = title == '' ? 'Alert' : title;
    const alert = await alertController.create({
        cssClass: 'alertClass',
        header: title,
        subHeader: subTitle,
        message: message,
        buttons: ['OK'],
    });

    await alert.present();
};

const advancedAlert = async (title: string, subTitle: string, message: string, buttons: any, hideHaptic = false) => {
    if (!hideHaptic) {
        hapticsImpactHeavy();
    }
    title = title == '' ? 'Alert' : title;
    const alert = await alertController.create({
        cssClass: 'alertClass',
        header: title,
        subHeader: subTitle,
        message: message,
        buttons: buttons,
    });

    await alert.present();

    const { data, role } = await alert.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export { basicAlert, advancedAlert };
