import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "section-header",
  style: {"margin-top":"15px","margin-right":"25px","margin-bottom":"15px"}
}
const _hoisted_2 = {
  key: 1,
  class: "section-header",
  style: {"margin-top":"15px","margin-right":"25px","margin-bottom":"15px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, { title: "Policies" }, {
    default: _withCtx(() => [
      (_ctx.store.reservationData.company?.cancellation_policy)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_ion_label, { class: "section-title" }, {
              default: _withCtx(() => [
                _createTextVNode("Cancellation Policy")
              ]),
              _: 1
            }),
            _createVNode(_component_ion_label, { id: "cancellation_policy" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.store.reservationData.company?.cancellation_policy), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.store.reservationData.company?.deposit_policy)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ion_label, { class: "section-title" }, {
              default: _withCtx(() => [
                _createTextVNode("Deposit Policy")
              ]),
              _: 1
            }),
            _createVNode(_component_ion_label, { id: "deposit_policy" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.store.reservationData.company?.deposit_policy), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}