import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-92340544"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "matterport-tour"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, {
    title: "Virtual Tour",
    noClass: "",
    styleString: "height: 100%;"
  }, {
    default: _withCtx(() => [
      (_ctx.link)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("iframe", {
              src: _ctx.link,
              frameborder: "0",
              allow: "fullscreen",
              allowfullscreen: "",
              width: "100%",
              height: "100%"
            }, null, 8, _hoisted_2)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode("No virtual tour available")
              ]),
              _: 1
            })
          ]))
    ]),
    _: 1
  }))
}