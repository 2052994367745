<template>
    <modal-base-layout title="Notifications" noClass>
        <template #header>
            <ion-item lines="none">
                <ion-select v-model="store.showArchived" placeholder="Select Filter" label="Filter By">
                    <ion-select-option :value="false">Active Notifications</ion-select-option>
                    <ion-select-option :value="true">Archived Notifications</ion-select-option>
                </ion-select>
            </ion-item>
        </template>

        <div>
            <!--
                No Notification Card
            -->
            <div
                style="width: 100%; height: 100%; overflow: hidden; position: fixed"
                class="bell-label"
                ref="bellLabel"
            >
                <div style="width: 70%; height: 35%; margin: 15%; margin-bottom: 0px">
                    <img style="width: 100%; height: 100%" src="@/assets/icons/notifications-icon.svg" />
                </div>
                <div style="display: flex; justify-content: center; padding: 0px; margin: 0px">
                    <ion-label style="font-size: x-large; font-weight: 800">No Notifications</ion-label>
                </div>
            </div>

            <!--
                Notification List
            -->
            <div style="margin-top: 2px">
                <ion-list ref="notificationList" style="background: transparent; margin: auto" lines="full">
                    <ion-item-sliding
                        v-for="(item, index) in store.pushNotificationList"
                        :key="index"
                        @ionSwipe="archiveMessage($event, item.id.toString(), index)"
                    >
                        <ion-item
                            ref="ionItem"
                            style="margin: 0px; border-right: 1px solid lightgray; background: var(--background-color)"
                        >
                            <div
                                slot="start"
                                style="
                                    margin: 0px;
                                    margin-bottom: 8px;
                                    margin-top: 8px;
                                    background: var(--background-color);
                                    width: -webkit-fill-available;
                                    cursor: pointer;
                                "
                                @click="openNotification(item)"
                            >
                                <div>
                                    <div style="display: flex; align-items: start; justify-content: space-between">
                                        <ion-label class="title-label" :class="{ unread: !item.read_status }" text-wrap>
                                            {{ item.title }}
                                        </ion-label>
                                        <ion-label style="text-wrap: nowrap; text-align: right; font-size: 12px">{{
                                            formatDate(item.created)
                                        }}</ion-label>
                                    </div>
                                    <div style="margin: 10px 0">
                                        <ion-label class="message-label" text-wrap>{{ item.message }}</ion-label>
                                    </div>
                                </div>
                            </div>
                        </ion-item>
                        <ion-item-options side="end">
                            <div ref="checkmark" class="checkmark">
                                <img style="width: 75px; height: 75px" src="@/assets/icons/checkmark-icon.svg" />
                            </div>
                            <ion-item-option
                                color="transpartent"
                                expandable="true"
                                side="end"
                                @click="archiveMessage('', item.id.toString(), index)"
                            >
                                <ion-icon
                                    ref="icon"
                                    style="width: 50px; height: 50px"
                                    :icon="archiveOutline"
                                    v-if="!item.archived"
                                />
                                <ion-icon ref="icon" style="width: 50px; height: 50px" :icon="refreshOutline" v-else />
                            </ion-item-option>
                        </ion-item-options>
                    </ion-item-sliding>
                </ion-list>
            </div>
        </div>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, ref, watch } from 'vue';
    import { useStore } from '@/store/store';
    import {
        chevronUp,
        close,
        ellipse,
        settings,
        checkmark,
        reload,
        checkmarkOutline,
        archiveOutline,
        refreshOutline,
    } from 'ionicons/icons';
    import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
    import { Capacitor } from '@capacitor/core';
    import AppFunctions from '@/services/AppFunctions';
    import { formatDate } from '@/services/Date';
    import { notificationDetailsModal } from '@/services/ModalController';
    import AppAPI from '@/services/AppAPI';
    import { errorToast } from '@/services/ToastController';

    export default defineComponent({
        components: {},
        props: {
            id: {
                type: String,
                required: false,
                default: '',
            },
        },
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();
            const ionMenu = ref();
            const waitTimer = ref(500); //min of 300
            const showNotificationList = ref(false);
            const bellLabel = ref();
            const ionItem = ref();
            const icon = ref();
            const notificationList = ref();
            const checkmark = ref();

            const closeMenu = () => {
                ionMenu.value.$el.close();
            };

            const openDeviceSettings = async () => {
                NativeSettings.open({
                    optionAndroid: AndroidSettings.ApplicationDetails,
                    optionIOS: IOSSettings.App,
                });
            };

            const handleRefresh = async () => {
                await AppFunctions.updatePushNotificationList();
            };

            const openNotification = async (item: any) => {
                const data = await notificationDetailsModal(item);
                if (data.role === 'switch-reservation') {
                    modalController.dismiss();
                }
            };

            const archiveMessage = async (event: any, id: string, index: number) => {
                const item = ionItem;
                const icons = icon;
                if (item.value != null) {
                    item.value[index].$el.style.visibility = 'hidden';
                    if (notificationList.value != null) {
                        notificationList.value.$el.closeSlidingItems();
                    }
                }
                if (icons.value != null) {
                    icons.value[index].$el.style.visibility = 'hidden';
                }
                showCheckmark(index);

                await AppAPI.setPushNotificationArchived(id);
                setTimeout(async () => {
                    await AppFunctions.updatePushNotificationList();
                    if (item.value != null && icons.value != null) {
                        try {
                            item.value[index].$el.style.visibility = 'visible';
                            icons.value[index].$el.style.visibility = 'visible';
                        } catch (error) {
                            console.log('No more notifications. ' + error);
                        }
                    }
                }, waitTimer.value);
            };

            const markAllRead = async () => {
                //
            };

            const getPlatform = () => {
                return Capacitor.getPlatform();
            };

            const showCheckmark = (index: any) => {
                checkmark.value[index].style.opacity = 1;

                setTimeout(function () {
                    checkmark.value[index].style.opacity = 0;
                }, waitTimer.value - 100);
            };

            const updateBell = () => {
                try {
                    if (store.pushNotificationList) {
                        if (store.pushNotificationList.length > 0) {
                            showNotificationList.value = true;
                            bellLabel.value.style.display = 'none';
                        } else {
                            showNotificationList.value = false;
                            bellLabel.value.style.display = 'block';
                        }
                    }
                } catch (error) {
                    console.log('Error:', error);
                }
            };

            onMounted(async () => {
                store.showArchived = false;
                await AppFunctions.updatePushNotificationList();
                updateBell();

                if (props.id && store.pushNotificationList) {
                    const item = store.pushNotificationList.find((item) => item.id == props.id);
                    if (item) {
                        openNotification(item);
                    } else {
                        errorToast('Notification ' + props.id + ' not found');
                    }
                }
            });

            watch(
                () => store.pushNotificationList,
                () => {
                    updateBell();
                }
            );

            watch(
                () => store.showArchived,
                () => {
                    AppFunctions.updatePushNotificationList();
                }
            );

            return {
                ionRouter,
                store,
                ionMenu,
                close,
                ellipse,
                chevronUp,
                settings,
                checkmark,
                checkmarkOutline,
                reload,
                icon,
                ionItem,
                notificationList,
                bellLabel,
                showNotificationList,
                archiveOutline,
                refreshOutline,
                formatDate,
                closeMenu,
                openDeviceSettings,
                getPlatform,
                archiveMessage,
                markAllRead,
                openNotification,
                handleRefresh,
            };
        },
    });
</script>

<style scoped>
    ion-header {
        --background: var(--background-color);
    }

    ion-label {
        color: black;
    }

    .bell-label {
        opacity: 1;
        z-index: -1;
        animation: fade-in 0.5s forwards;
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .checkmark {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 99;
        transform: translate(-50%, -50%);
        opacity: 0;
        font-size: 100px;
        color: black;
        transition: opacity 0.5s ease-in-out;
    }

    .title-label {
        font-size: 18px;
        color: black;
        align-items: center;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        padding-right: 10px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
    }

    .title-label.unread {
        font-weight: 800;
    }

    .message-label {
        font-size: medium;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-clamp: 3;
    }
</style>
