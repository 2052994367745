<template>
    <modal-base-layout
        :imageUrl="props.item.image || 'assets/img/no_image.png'"
        :title="props.item.name || 'N/A'"
        :subtitle="subtitle"
    >
        <div style="text-align: left">
            <ion-item lines="full">
                <div style="padding-top: 10px; width: 100%">
                    <ion-label v-if="!showAllHours" style="display: flex; justify-content: space-between"
                        ><span style="font-weight: bold">Today:</span>
                        <span>{{ getDuringStayItemTodayHours(props.item) }}</span></ion-label
                    >
                    <div v-if="showAllHours" style="width: 100%">
                        <ion-label style="font-weight: bold">All Hours</ion-label>
                        <template v-for="(hour, index) in getDuringStayItemAllHours(props.item)" :key="index">
                            <ion-label style="margin: 5px 0px; display: flex; justify-content: space-between">
                                <span>{{ AppFunctions.capitalizeFirstLetter(hour.day) }}:</span>
                                <span>{{ hour.hours }}</span>
                            </ion-label>
                        </template>
                    </div>
                    <ion-label
                        style="padding: 15px 0px 10px; font-weight: bold; color: var(--ion-color-primary-shade)"
                        @click="showAllHours = !showAllHours"
                        >{{ showAllHours ? 'Hide All Hours' : 'View All Hours' }}</ion-label
                    >
                </div>
            </ion-item>
            <ion-item lines="full" v-if="item.description">
                <div>
                    <ion-label style="font-size: 16px; padding: 5px 0px"> {{ item.description }}</ion-label>
                </div>
            </ion-item>
            <ion-item lines="full" v-if="item.address.address1 && item.address.short_name">
                <div style="padding-top: 10px">
                    <ion-label
                        ><span style="font-weight: bold">Address</span>
                        {{ item.address.address1 + ', ' + item.address.short_name }}</ion-label
                    >
                    <ion-label
                        style="padding: 15px 0px 10px; font-weight: bold; color: var(--ion-color-primary-shade)"
                        @click="
                            advancedActionSheet(
                                'Open with',
                                openInMaps(Capacitor.getPlatform()),
                                item.address.address1 + ', ' + item.address.short_name
                            )
                        "
                        >View Map</ion-label
                    >
                </div>
            </ion-item>
        </div>

        <div style="position: fixed; z-index: 99; width: 100%; padding-bottom: 15px; bottom: 0" v-if="item.website_url">
            <ion-button @click="openLink()">Learn More</ion-button>
        </div>
    </modal-base-layout>
</template>

<script lang="ts">
    import { useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, ref, computed, watch } from 'vue';
    import { bicycle, closeOutline, home, restaurant, trailSign, chevronForward } from 'ionicons/icons';
    import { useStore } from '@/store/store';
    import 'leaflet/dist/leaflet.css';
    import AppFunctions from '@/services/AppFunctions';
    import { getDuringStayItemTodayHours, getDuringStayItemAllHours } from '@/services/Date';
    import { advancedActionSheet } from '@/services/ActionController';
    import { openInMaps } from '@/services/ActionSheetOptions';
    import { Capacitor } from '@capacitor/core';

    export default defineComponent({
        components: {
            //
        },
        props: {
            item: {
                type: Object,
                default: () => {
                    return {};
                },
            },
        },
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();
            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });
            const refreshItemView = ref(true);
            const showAllHours = ref(false);

            const openLink = () => {
                window.open(props.item.website_url, '_blank');
            };

            const subtitle = computed(() => {
                return props.item.cuisine || props.item.sport_type || '';
            });

            watch(
                () => store.selectedTechReservationId,
                () => {
                    refreshItemView.value = false;
                    setTimeout(function () {
                        refreshItemView.value = true;
                    }, 50);
                }
            );

            onMounted(() => {
                //
            });

            return {
                ionRouter,
                closeOutline,
                store,
                bicycle,
                chevronForward,
                restaurant,
                trailSign,
                home,
                selectedProperty,
                refreshItemView,
                props,
                AppFunctions,
                openInMaps,
                subtitle,
                Capacitor,
                showAllHours,
                getDuringStayItemAllHours,
                advancedActionSheet,
                openLink,
                getDuringStayItemTodayHours,
            };
        },
    });
</script>

<style scoped>
    ion-card {
        margin: 2px;
    }
    ion-item {
        --padding-top: 5px;
        --padding-bottom: 5px;
    }
</style>
