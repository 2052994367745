<template>
    <modal-base-layout title="Notification Details">
        <div style="margin: 10px">
            <div style="display: flex; justify-content: space-between; align-items: center">
                <ion-label
                    style="font-size: 22px; font-weight: 800; color: black; align-items: center; text-align: left"
                    text-wrap
                    >{{ item.title }}</ion-label
                >
                <ion-label style="text-align: right">{{ formatDateWithTime(item.created) }}</ion-label>
            </div>
            <div style="text-align: left; margin-top: 15px">
                <ion-label style="font-size: medium" text-wrap>{{ item.message }}</ion-label>
            </div>
        </div>

        <template #footer>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-button
                            style="width: -webkit-fill-available; --border-radius: 10px"
                            color="primary"
                            @click="cancel()"
                            >Back</ion-button
                        >
                    </ion-col>
                </ion-row>
            </ion-grid>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, ref } from 'vue';
    import { closeOutline } from 'ionicons/icons';
    import { useStore, pushNotificationListType } from '@/store/store';
    import { formatDate, formatDateWithTime, formatTime } from '@/services/Date';
    import AppAPI from '@/services/AppAPI';
    import AppFunctions from '@/services/AppFunctions';

    export default defineComponent({
        components: {
            //
        },
        props: {
            item: {
                type: Object as () => pushNotificationListType,
                required: true,
            },
        },
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();

            const cancel = async () => {
                modalController.dismiss();
            };

            onMounted(async () => {
                const notification_message = document.getElementById('notification_message');
                if (notification_message) {
                    notification_message.innerHTML = props.item.message;
                }

                if (!props.item.read_status) {
                    await AppAPI.setReadPushNotification(props.item.id);
                    await AppFunctions.updatePushNotificationList();
                }
            });

            return {
                ionRouter,
                closeOutline,
                store,
                formatDate,
                cancel,
                confirm,
                formatDateWithTime,
                formatTime,
            };
        },
    });
</script>

<style scoped></style>
