import { Capacitor } from '@capacitor/core';

export default {
    async connectToWiFi(ssid: string, password: string) {
        const platform = Capacitor.getPlatform();
        if (platform === 'ios') {
            const result = await (window as any).WifiWizard2.iOSConnectNetwork(ssid, password ? password : false);
            return true;
        }
        if (platform === 'android') {
            const result = await (window as any).WifiWizard2.suggestConnection(
                ssid,
                password ? password : '',
                password ? 'WPA' : '',
                false
            );
            if (result == 'STATUS_NETWORK_SUGGESTIONS_ADDED') {
                return true;
            } else {
                return false;
            }
        }

        return false;
    },
};
