<template>
    <modal-base-layout title="Push Notifications">
        <ion-card>
            <ion-card-header>
                <ion-label class="section-title">Enable Push Notifications</ion-label>
            </ion-card-header>
            <ion-card-content>
                <ion-item lines="none">
                    <ion-label>
                        Please enable push notifications to receive important notifications about new messages and
                        alerts about your stay.
                    </ion-label>
                </ion-item>
                <ion-item lines="none">
                    <ion-label> You can enable push notifications in your device settings. </ion-label>
                </ion-item>
                <ion-button
                    @click="openDeviceSettings"
                    style="display: flex; justify-content: center; margin: 5px 15px 15px"
                >
                    Open Device Settings
                </ion-button>
            </ion-card-content>
        </ion-card>

        <ion-card>
            <ion-card-header>
                <ion-label class="section-title">Disable Warning</ion-label>
            </ion-card-header>
            <ion-card-content>
                <ion-item lines="none">
                    <ion-label> If you don't want to see this warning again, you can disable it. </ion-label>
                </ion-item>
                <ion-item lines="none" style="margin: 0px 0px 15px">
                    <ion-label slot="start">Disable Warning</ion-label>
                    <ion-toggle
                        slot="end"
                        v-model="store.pushNotificationReminderHidden"
                        @ionChange="updateToggleState"
                    />
                </ion-item>
            </ion-card-content>
        </ion-card>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import { bicycle } from 'ionicons/icons';
    import { useStore } from '@/store/store';
    import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';

    export default defineComponent({
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();

            const updateToggleState = () => {
                store.updatepushNotificationReminderHidden(store.pushNotificationReminderHidden);
            };

            const openDeviceSettings = () => {
                NativeSettings.open({
                    optionAndroid: AndroidSettings.ApplicationDetails,
                    optionIOS: IOSSettings.App,
                });
                modalController.dismiss();
            };

            return {
                ionRouter,
                store,
                bicycle,
                updateToggleState,
                openDeviceSettings,
            };
        },
    });
</script>

<style scoped></style>
