<template>
    <modal-base-layout title="">
        <div class="app-info">
            <img class="app-logo" src="@/assets/nameLogo.png" alt="Staylio Logo" />

            <p class="app-description">
                Staylio provides hosts with a powerful tool to control, customize and manage the guest experience.
                Whether it be check-in / check-out procedures, messaging, chatting, signing of the rental agreement or
                leaving a review, Staylio has it covered!
            </p>

            <div style="background-color: lightgray; margin: 15px 0">
                <img
                    class="app-logo"
                    src="https://staylio.com/wp-content/uploads/elementor/thumbs/lodgix-bw-qk8qrd5f58ljahobfszudfcqw5igsd2vkk6powftjq.png"
                    alt="Staylio Logo"
                />

                <p class="app-description">
                    Staylio is a product of <strong>Lodgix.com</strong>. Please visit our website to learn more about
                    Lodgix. <br /><br />
                    An active Lodgix subscription is required for Property Managers to allow guests to use Staylio.
                </p>
            </div>
        </div>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, ref } from 'vue';
    import { bicycle, closeOutline, home, restaurant, trailSign, chevronForward } from 'ionicons/icons';
    import { useStore } from '@/store/store';

    export default defineComponent({
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();

            onMounted(() => {
                //
            });

            return {
                ionRouter,
                closeOutline,
                store,
                bicycle,
                chevronForward,
                restaurant,
                trailSign,
                home,
            };
        },
    });
</script>

<style scoped>
    .app-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .app-name {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .app-description {
        font-size: 16px;
        text-align: left;
        margin: 10px 20px;
    }

    .app-logo {
        width: 150px;
        margin: 10px 0;
    }
</style>
