import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"100px"} }
const _hoisted_2 = {
  class: "section-header",
  style: {"margin-top":"15px","margin-right":"25px"}
}
const _hoisted_3 = {
  class: "section-header",
  style: {"margin-top":"15px","margin-right":"25px"}
}
const _hoisted_4 = {
  class: "section-header",
  style: {"margin-top":"15px","margin-right":"25px"}
}
const _hoisted_5 = {
  class: "section-header",
  style: {"margin-top":"15px","margin-right":"25px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaymentStatusCard = _resolveComponent("PaymentStatusCard")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, { title: "Payment" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_PaymentStatusCard),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ion_label, { class: "section-title" }, {
            default: _withCtx(() => [
              _createTextVNode("Confirmation Amount")
            ]),
            _: 1
          }),
          _createVNode(_component_ion_label, { style: {"margin-top":"10px"} }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.store.reservationData.confirmation_amount), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ion_label, { class: "section-title" }, {
            default: _withCtx(() => [
              _createTextVNode("Total Amount")
            ]),
            _: 1
          }),
          _createVNode(_component_ion_label, { style: {"margin-top":"10px"} }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.store.reservationData.total), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ion_label, { class: "section-title" }, {
            default: _withCtx(() => [
              _createTextVNode("Total Amount with Deposits")
            ]),
            _: 1
          }),
          _createVNode(_component_ion_label, { style: {"margin-top":"10px"} }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.store.reservationData.total_with_deposits), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_ion_label, { class: "section-title" }, {
            default: _withCtx(() => [
              _createTextVNode("Amount Paid")
            ]),
            _: 1
          }),
          _createVNode(_component_ion_label, { style: {"margin-top":"10px"} }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.store.reservationData.paid), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}