<template>
    <modal-base-layout title="Rental Agreement">
        <template v-for="rentalAgreement in rentalAgreements" :key="rentalAgreement">
            <ion-card v-if="rentalAgreement?.sign_url" style="margin: 5px 5px 10px">
                <ion-card-header>
                    <ion-label class="section-title">Rental Agreement</ion-label>
                </ion-card-header>
                <ion-card-content style="text-align: center; margin-bottom: 10px">
                    <ion-item lines="none">
                        <ion-label> Please click the button below to review and sign the rental agreement. </ion-label>
                    </ion-item>
                    <ion-button @click="openLink(rentalAgreement?.sign_url)"> Sign Rental Agreement </ion-button>
                </ion-card-content>
            </ion-card>
        </template>

        <div v-if="pdfViewData">
            <VuePDF fitParent :pdf="pdfViewData" :page="viewPage" />
            <div style="display: flex; justify-content: space-between; align-items: center; margin: 10px">
                <ion-button style="width: 125px" @click="changePage(-1)">Previous</ion-button>
                <div>
                    <ion-label style="font-weight: bold">{{ viewPage }} / {{ maxPages }}</ion-label>
                </div>
                <ion-button style="width: 125px" @click="changePage(1)">Next</ion-button>
            </div>
            <div v-if="pdfLink">
                <ion-button
                    style="width: 100%; padding: 0 10px; justify-content: center; align-items: center"
                    @click="openLink(pdfLink)"
                >
                    <ion-icon :src="download" style="margin-right: 10px" />
                    <div>Download Agreement</div>
                </ion-button>
            </div>
        </div>

        <div v-else-if="!store.reservationData.sign_allowed">
            <ion-card style="margin: 5px 5px 10px">
                <ion-card-header>
                    <ion-label class="section-title">Rental Agreement</ion-label>
                </ion-card-header>
                <ion-card-content style="text-align: center; margin-bottom: 10px">
                    <ion-item lines="none">
                        <ion-label
                            >Once a signed rental agreement is ready for viewing, it will be displayed here.</ion-label
                        >
                    </ion-item>
                </ion-card-content>
            </ion-card>
        </div>

        <div v-else-if="rentalAgreements.length == 0">
            <ion-card style="margin: 5px 5px 10px">
                <ion-card-header>
                    <ion-label class="section-title">Rental Agreement</ion-label>
                </ion-card-header>
                <ion-card-content style="text-align: center; margin-bottom: 10px">
                    <ion-item lines="none">
                        <ion-label
                            >There was an issue loading the rental agreement. Please contact the Property Manager for
                            assistance.</ion-label
                        >
                    </ion-item>
                </ion-card-content>
            </ion-card>
        </div>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, ref, watch } from 'vue';
    import { bicycle, closeOutline, home, restaurant, trailSign, chevronForward, download } from 'ionicons/icons';
    import { useStore } from '@/store/store';
    import AppAPI from '@/services/AppAPI';
    import { VuePDF, usePDF } from '@tato30/vue-pdf';
    import * as PDFJS from 'pdfjs-dist';
    import { Browser } from '@capacitor/browser';
    import AppFunctions from '@/services/AppFunctions';
    import { showLoading, hideLoading } from '@/services/LoadingController';

    export default defineComponent({
        components: {
            VuePDF,
        },
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();
            const rentalAgreements = ref([] as any[]);
            const pdfViewData = ref();
            const viewPage = ref(1);
            const maxPages = ref(1);
            const pdfLink = ref('');

            const downloadPDF = async (url: string) => {
                const loadingController = await showLoading('Downloading...');
                try {
                    const { pdf, pages } = usePDF(url);
                    const loadingTask = PDFJS.getDocument(url);

                    if (loadingTask.promise) {
                        loadingTask.promise.then((pdf) => {
                            maxPages.value = pdf.numPages;
                            pdfViewData.value = loadingTask;
                        });
                        pdfLink.value = url;
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    hideLoading(loadingController);
                }
            };

            const changePage = async (changeAmount: number) => {
                if (viewPage.value + changeAmount < 1) {
                    return;
                }
                if (viewPage.value + changeAmount > maxPages.value) {
                    return;
                }

                viewPage.value += changeAmount;
            };

            const openLink = async (url: string) => {
                await Browser.open({ url: url });
                await Browser.addListener('browserFinished', async () => {
                    await loadRentalAgreement();
                    await AppFunctions.refreshCurrentReservationData();
                    for (const rentalAgreement of rentalAgreements.value) {
                        if (rentalAgreement?.view_url) {
                            downloadPDF(rentalAgreement?.view_url);
                        }
                    }
                });
            };

            const loadRentalAgreement = async () => {
                rentalAgreements.value = (await AppAPI.getRentalAgreement()).data;
                if (rentalAgreements.value.length > 0) {
                    rentalAgreements.value = [rentalAgreements.value[0]];
                }
            };

            onMounted(async () => {
                const loadingController = await showLoading('Loading...');
                try {
                    await loadRentalAgreement();
                    for (const rentalAgreement of rentalAgreements.value) {
                        if (rentalAgreement?.view_url) {
                            downloadPDF(rentalAgreement?.view_url);
                        }
                    }
                } catch (error) {
                    //console.error(error);
                } finally {
                    hideLoading(loadingController);
                }
            });

            return {
                ionRouter,
                closeOutline,
                store,
                bicycle,
                chevronForward,
                restaurant,
                trailSign,
                home,
                rentalAgreements,
                pdfViewData,
                openLink,
                maxPages,
                viewPage,
                changePage,
                pdfLink,
                download,
            };
        },
    });
</script>

<style scoped></style>
