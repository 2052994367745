<template>
    <ion-page id="main-content">
        <ion-header mode="ios" v-if="accessToken">
            <template v-if="!noHeader">
                <ion-toolbar mode="md" class="tabs-header">
                    <div style="display: flex">
                        <ion-buttons slot="start" v-if="!hideSideMenu">
                            <ion-menu-button />
                        </ion-buttons>
                        <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                            <ion-title
                                v-if="accessToken && !noTitle && !store.viewingChatWithoutReservation"
                                style="font-weight: 700; font-size: 20px; padding: 0px; margin: 0px"
                                >{{ pageTitle || selectedProperty?.rental_property?.name }}</ion-title
                            >
                            <div v-if="accessToken && !noTitle && store.selectedReservationId">
                                <ion-label
                                    v-if="!store.viewingChatWithoutReservation"
                                    style="font-weight: 700; font-size: 12px; padding: 0px; margin: 0px"
                                    >Invoice #{{ store.selectedReservationId }}</ion-label
                                >
                                <div
                                    v-else
                                    v-html="formatSubject"
                                    style="font-weight: 700; font-size: 14px; padding: 0; margin: 0"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="!isProduction && router && false"
                        style="
                            background-color: rgb(255, 187, 51);
                            border-bottom: 1px solid lightgrey;
                            text-align: center;
                        "
                    >
                        <ion-label style="color: black">URL: {{ router?.currentRoute?.value?.fullPath }}</ion-label>
                    </div>
                    <div
                        v-if="!isProduction"
                        style="
                            background-color: rgb(255, 187, 51);
                            border-bottom: 1px solid lightgrey;
                            text-align: center;
                        "
                    >
                        <ion-label style="color: black"
                            >Connection:
                            <span style="font-weight: bold">{{
                                isStaging ? 'Lodgix Staging' : connectionUrl
                            }}</span></ion-label
                        >
                    </div>
                </ion-toolbar>
            </template>
        </ion-header>
        <ion-content :class="[isChat ? 'messages' : '']" mode="ios" :fullscreen="true">
            <div
                v-if="noHeader"
                style="position: fixed; top: 0; height: env(safe-area-inset-top); width: 100%; z-index: 1000 !important"
                :style="[!isChat ? 'background-color: var(--background-color);' : 'background-color: #fff']"
            />
            <div :style="[noHeader ? 'padding-top: env(safe-area-inset-top);' : '']">
                <slot />
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
    import { useIonRouter } from '@ionic/vue';
    import { computed, defineComponent } from 'vue';
    import { useStore } from '@/store/store';
    import { notificationsOutline } from 'ionicons/icons';
    import { Capacitor } from '@capacitor/core';
    import { accessToken } from '@/services/Authentication';
    import AppFunctions from '@/services/AppFunctions';
    import { connectionUrl, isProduction, isStaging } from '@/constants';
    import router from '@/router';

    export default defineComponent({
        components: {},
        props: {
            pageTitle: {
                type: String,
                default: '',
            },
            noTitle: {
                type: Boolean,
                default: false,
            },
            noHeader: {
                type: Boolean,
                default: false,
            },
            isChat: {
                type: Boolean,
                default: false,
            },
            hideSideMenu: {
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();
            const os = Capacitor.getPlatform();

            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            const formatSubject = computed(() => {
                if (store.currentConversationSubject.includes('-')) {
                    const split = store.currentConversationSubject.split('-');
                    return '<p style="margin: 2px;">' + split[0] + '</p><p style="margin: 2px;">' + split[1] + '</p>';
                }
                return store.currentConversationSubject;
            });

            const goHome = () => {
                ionRouter.replace('/tabs/home');
            };

            const signedIn = () => {
                return true;
            };

            return {
                ionRouter,
                formatSubject,
                store,
                router,
                notificationsOutline,
                os,
                accessToken,
                AppFunctions,
                selectedProperty,
                isProduction,
                connectionUrl,
                isStaging,
                goHome,
                signedIn,
            };
        },
    });
</script>

<style scoped></style>
