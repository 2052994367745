<template>
    <ion-card>
        <ion-card-header>
            <ion-label class="section-title">Profile</ion-label>
        </ion-card-header>
        <ion-card-content>
            <ion-list lines="full">
                <ion-item>
                    <ion-label style="margin-left: 5px">Name</ion-label>
                    <ion-label slot="end" style="text-align: end">{{ displayName }}</ion-label>
                </ion-item>
                <ion-item v-if="store.user?.email">
                    <ion-label style="margin-left: 5px">Email</ion-label>
                    <ion-label slot="end" style="text-align: end">{{ store.user?.email }}</ion-label>
                </ion-item>
                <ion-item v-if="store.user?.mobile_phone">
                    <ion-label style="margin-left: 5px">Phone</ion-label>
                    <ion-label slot="end" style="text-align: end">{{ store.user?.mobile_phone }}</ion-label>
                </ion-item>
                <ion-item v-if="store.user?.address?.address1 || store.user?.address?.short_name">
                    <ion-label style="margin-left: 5px">Address</ion-label>
                    <ion-label slot="end" style="text-align: end"
                        >{{ store.user?.address?.address1 }} <br />
                        {{ store.user?.address?.short_name }}</ion-label
                    >
                </ion-item>
                <ion-item v-if="Capacitor.getPlatform() == 'web'">
                    <ion-label style="margin-left: 5px">Account Type</ion-label>
                    <ion-label slot="end" style="text-align: end"
                        >{{ AppFunctions.isImpersonating() ? 'Shared' : 'Primary' }} User</ion-label
                    >
                </ion-item>
                <ion-item v-if="store.isBetaUser">
                    <ion-label style="margin-left: 5px">Beta Features</ion-label>
                    <ion-label slot="end" style="text-align: end">{{
                        store.isBetaUser ? 'Enabled' : 'Disabled'
                    }}</ion-label>
                </ion-item>
            </ion-list>
        </ion-card-content>
    </ion-card>
</template>

<script lang="ts">
    import { useIonRouter } from '@ionic/vue';
    import { computed, defineComponent } from 'vue';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';
    import { Capacitor } from '@capacitor/core';

    export default defineComponent({
        components: {},
        setup() {
            const store = useStore();
            const ionRouter = useIonRouter();

            const displayName = computed(() => {
                if (store.user) {
                    if (!AppFunctions.isImpersonating()) {
                        return `${store.user.first_name} ${store.user.last_name}`;
                    } else {
                        return store.user.name;
                    }
                }
                return '';
            });

            return {
                ionRouter,
                store,
                AppFunctions,
                displayName,
                Capacitor,
            };
        },
    });
</script>

<style scoped></style>
