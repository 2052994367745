<template>
    <modal-base-layout showPropertySegment title="Arrival Info">
        <div style="margin-bottom: 100px; padding-top: 10px">
            <CheckInOutCard isCheckIn />

            <WifiDetails />

            <DoorCode />

            <PropertyAddressDirections />

            <HouseManual />

            <PropertyCustomVariables :location="2" />
        </div>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, computed, ref, watch } from 'vue';
    import { alertCircleOutline, closeOutline, copyOutline, logoApple, logoGoogle, openOutline } from 'ionicons/icons';
    import { useStore } from '@/store/store';
    import { infoToast } from '@/services/ToastController';
    import AppFunctions from '@/services/AppFunctions';
    import { formatTime } from '@/services/Date';
    import WifiDetails from '@/components/SelectedPropertyComponents/WifiDetails.vue';
    import { Capacitor } from '@capacitor/core';
    import DoorCode from '@/components/SelectedPropertyComponents/DoorCode.vue';
    import PropertyAddressDirections from '@/components/SelectedPropertyComponents/PropertyAddressDirections.vue';
    import CheckInOutCard from '@/components/SelectedPropertyComponents/CheckInOutCard.vue';
    import HouseManual from '../SelectedPropertyComponents/HouseManual.vue';
    import PropertyCustomVariables from '../SelectedPropertyComponents/PropertyCustomVariables.vue';

    export default defineComponent({
        components: {
            WifiDetails,
            PropertyAddressDirections,
            DoorCode,
            CheckInOutCard,
            HouseManual,
            PropertyCustomVariables,
        },
        props: {
            //
        },
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();
            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });
            const refreshItemView = ref(true);

            const copyWifi = async (wifiPasscode: string) => {
                await AppFunctions.writeToClipboard(wifiPasscode);
                infoToast('Wifi Password Copied');
            };

            onMounted(async () => {
                await AppFunctions.refreshCurrentReservationData();
            });

            watch(
                () => store.selectedTechReservationId,
                () => {
                    refreshItemView.value = false;
                    setTimeout(function () {
                        refreshItemView.value = true;
                    }, 50);
                }
            );

            return {
                ionRouter,
                closeOutline,
                store,
                alertCircleOutline,
                copyOutline,
                openOutline,
                selectedProperty,
                AppFunctions,
                Capacitor,
                formatTime,
                copyWifi,
            };
        },
    });
</script>

<style scoped></style>
