import { format, getDay, parse } from 'date-fns';
import { useStore } from '@/store/store';

export function isMonthFirst() {
    const store = useStore();
    let isMonthFirst = true;
    if (store.user.date_format) {
        isMonthFirst = store.user.date_format[0] === 'M';
    }
    return isMonthFirst;
}

export function formatTime(time: string) {
    if (!time) {
        return 'no time';
    }
    const result = format(new Date(time), 'h:mm a');
    return result;
}

export function formatDate(date: string) {
    if (!date) {
        return '';
    }
    const formatTemplate = `${isMonthFirst() ? 'MMM dd, yyyy' : 'dd MMM yyyy'}`;
    const result = format(new Date(date), formatTemplate);
    return result;
}

export function formatDateWithTime(date: string) {
    if (!date) {
        return '';
    }
    const formatTemplate = `${isMonthFirst() ? 'MMM dd, yyyy' : 'dd MMM yyyy'} h:mm a`;
    const result = format(new Date(date), formatTemplate);
    return result;
}

export function formatDateSentance(date: string) {
    const store = useStore();
    if (!date) {
        return '';
    }

    const time = format(new Date(date), 'h:mm a');
    const dateFormat = format(new Date(date), `EEEE, ${isMonthFirst() ? 'MMMM dd, yyyy' : 'dd MMMM yyyy'}`);
    const result = `${time} on ${dateFormat}`;
    return result;
}

export function getTodayDay() {
    const result = getDay(new Date())
        .toString()
        .replace('0', 'Sunday')
        .replace('1', 'Monday')
        .replace('2', 'Tuesday')
        .replace('3', 'Wednesday')
        .replace('4', 'Thursday')
        .replace('5', 'Friday')
        .replace('6', 'Saturday');
    return result;
}

export function getDuringStayItemTodayHours(item: any) {
    const day = getTodayDay().toLocaleLowerCase();
    const openKey = `${day}_open`;
    const closeKey = `${day}_close`;
    const isClosed = item[`${day}_is_closed`];

    if (isClosed) {
        return 'Closed';
    }

    let openTime = item[openKey] || 'N/A';
    let closeTime = item[closeKey] || 'N/A';

    if (openTime !== 'N/A') {
        const parsedOpenTime = parse(openTime, 'HH:mm:ss', new Date());
        openTime = format(parsedOpenTime, 'hh:mm a');
    }

    if (closeTime !== 'N/A') {
        const parsedCloseTime = parse(closeTime, 'HH:mm:ss', new Date());
        closeTime = format(parsedCloseTime, 'hh:mm a');
    }

    if (openTime === 'N/A' && closeTime === 'N/A') {
        return 'Hours Unavailable';
    }

    return `${openTime} - ${closeTime}`;
}

export function getDuringStayItemAllHours(item: any) {
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const result = days.map((day) => {
        const openKey = `${day}_open`;
        const closeKey = `${day}_close`;
        const isClosed = item[`${day}_is_closed`];

        let openTime = item[openKey] || 'N/A';
        let closeTime = item[closeKey] || 'N/A';
        let hours = '';

        if (openTime !== 'N/A') {
            const parsedOpenTime = parse(openTime, 'HH:mm:ss', new Date());
            openTime = format(parsedOpenTime, 'hh:mm a');
        }

        if (closeTime !== 'N/A') {
            const parsedCloseTime = parse(closeTime, 'HH:mm:ss', new Date());
            closeTime = format(parsedCloseTime, 'hh:mm a');
        }

        if (openTime === 'N/A' && closeTime === 'N/A') {
            hours = 'Hours Unavailable';
        } else {
            hours = `${openTime} - ${closeTime}`;
        }

        return {
            day: day,
            hours: isClosed ? 'Closed' : hours,
        };
    });
    return result;
}

export function calculateDaysDifferenceFromToday(givenDate: string) {
    const start = new Date();
    const end = new Date(givenDate);
    const difference = end.getTime() - start.getTime();
    const days = difference / (1000 * 3600 * 24);
    return days;
}
