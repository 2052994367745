import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, { title: "Push Notifications" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "section-title" }, {
                default: _withCtx(() => [
                  _createTextVNode("Enable Push Notifications")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_card_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Please enable push notifications to receive important notifications about new messages and alerts about your stay. ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" You can enable push notifications in your device settings. ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                onClick: _ctx.openDeviceSettings,
                style: {"display":"flex","justify-content":"center","margin":"5px 15px 15px"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Open Device Settings ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "section-title" }, {
                default: _withCtx(() => [
                  _createTextVNode("Disable Warning")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_card_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" If you don't want to see this warning again, you can disable it. ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                lines: "none",
                style: {"margin":"0px 0px 15px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { slot: "start" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Disable Warning")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_toggle, {
                    slot: "end",
                    modelValue: _ctx.store.pushNotificationReminderHidden,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.store.pushNotificationReminderHidden) = $event)),
                    onIonChange: _ctx.updateToggleState
                  }, null, 8, ["modelValue", "onIonChange"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}