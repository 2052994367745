import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ion_segment, {
      mode: "md",
      class: "header",
      modelValue: _ctx.store.selectedTechReservationId,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.store.selectedTechReservationId) = $event)),
      scrollable: true
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.reservationData.tech_reservations, (item, index) => {
          return (_openBlock(), _createBlock(_component_ion_segment_button, {
            key: index,
            class: "header",
            style: _normalizeStyle([_ctx.store.reservationData?.tech_reservations?.length <= 1 ? '--indicator-height: 0px;' : '']),
            value: item.id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { style: {"color":"var(--header-text-color)"} }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.truncateName(item.rental_property.name)), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["style", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}