<template>
    <modal-base-layout :title="props.category.name_plural">
        <template v-for="i in filteredItems(props.category.sysname).items" :key="i">
            <ion-card style="margin: 15px; cursor: pointer" @click="duringStayDetailModal(i)">
                <ion-card-content style="display: flex; margin: 5px">
                    <img
                        :src="i.image || 'assets/img/no_image.png'"
                        style="height: 100px; object-fit: cover; border-radius: 10px; aspect-ratio: 1/1"
                    />
                    <div style="display: flex; flex-direction: column; padding-left: 10px">
                        <ion-label style="font-weight: bold">{{ i.name }}</ion-label>
                        <div style="display: flex; flex-direction: column">
                            <ion-label style="font-size: 14px" v-if="i.cuisine">{{ i.cuisine }}</ion-label>
                            <ion-label style="font-size: 14px" v-if="i.sport_type">{{ i.sport_type }}</ion-label>
                            <ion-label style="font-size: 14px">Hours: {{ getDuringStayItemTodayHours(i) }}</ion-label>
                        </div>
                    </div>
                </ion-card-content>
            </ion-card>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, ref, computed, watch } from 'vue';
    import { bicycle, closeOutline, home, restaurant, trailSign, chevronForward } from 'ionicons/icons';
    import { useStore } from '@/store/store';
    import 'leaflet/dist/leaflet.css';
    import AppFunctions from '@/services/AppFunctions';
    import { duringStayDetailModal } from '@/services/ModalController';
    import { getDuringStayItemTodayHours } from '@/services/Date';

    export default defineComponent({
        components: {
            //
        },
        props: {
            category: {
                type: Object,
                default: () => {
                    return {};
                },
            },
        },
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();
            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });
            const refreshItemView = ref(true);

            const filteredItems = (category: string) => {
                const items = selectedProperty.value.rental_property.during_stay.items.filter(
                    (item: any) => item.category == category
                );
                return {
                    items: items ? items : [],
                    size: items ? items.length : 0,
                };
            };

            watch(
                () => store.selectedTechReservationId,
                () => {
                    refreshItemView.value = false;
                    setTimeout(function () {
                        refreshItemView.value = true;
                    }, 50);
                }
            );

            onMounted(() => {
                //
            });

            return {
                ionRouter,
                closeOutline,
                store,
                bicycle,
                chevronForward,
                restaurant,
                trailSign,
                home,
                selectedProperty,
                refreshItemView,
                props,
                AppFunctions,
                getDuringStayItemTodayHours,
                duringStayDetailModal,
                filteredItems,
            };
        },
    });
</script>

<style scoped>
    ion-card {
        margin: 2px;
    }
    ion-item {
        --padding-top: 5px;
        --padding-bottom: 5px;
    }
</style>
