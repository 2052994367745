<template>
    <modal-base-layout title="Virtual Tour" noClass styleString="height: 100%;">
        <div class="matterport-tour" v-if="link">
            <iframe :src="link" frameborder="0" allow="fullscreen" allowfullscreen width="100%" height="100%"></iframe>
        </div>
        <div v-else>
            <ion-label>No virtual tour available</ion-label>
        </div>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, ref } from 'vue';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';

    export default defineComponent({
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();
            const link = ref(AppFunctions.getSelectedPropertyData().rental_property.virtual_tours_code || '');

            onMounted(() => {
                //
            });

            return {
                ionRouter,
                store,
                link,
            };
        },
    });
</script>

<style scoped>
    .matterport-tour {
        height: 100%;
        overflow: hidden;
    }
</style>
