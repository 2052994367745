import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginWarning = _resolveComponent("LoginWarning")!
  const _component_UserInformation = _resolveComponent("UserInformation")!
  const _component_SettingsComponent = _resolveComponent("SettingsComponent")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, null, {
    default: _withCtx(() => [
      (!_ctx.accessToken)
        ? (_openBlock(), _createBlock(_component_LoginWarning, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_UserInformation),
            _createVNode(_component_SettingsComponent)
          ]))
    ]),
    _: 1
  }))
}