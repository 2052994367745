<template>
    <modal-base-layout title="Amenities">
        <ion-card>
            <ion-card-header>
                <ion-label class="section-title">{{ selectedProperty.rental_property.name }}</ion-label>
            </ion-card-header>
            <ion-card-content style="margin: 5px 15px">
                <ion-list>
                    <ion-item
                        v-for="(item, index) in items"
                        :key="index"
                        :lines="index == items.length - 1 ? 'none' : 'full'"
                    >
                        <ion-label>{{ item.name }}</ion-label>
                    </ion-item>
                </ion-list>
            </ion-card-content>
        </ion-card>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { computed, defineComponent, onMounted, ref } from 'vue';
    import { ellipse } from 'ionicons/icons';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';

    export default defineComponent({
        props: {
            items: {
                type: Array as () => Array<{ name: string }>,
                default: () => [],
            },
        },
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();

            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            onMounted(() => {
                //
            });

            return {
                ionRouter,
                store,
                ellipse,
                selectedProperty,
            };
        },
    });
</script>

<style scoped></style>
