<template>
    <ion-card>
        <ion-card-header>
            <ion-label class="section-title">Check Out Tasks</ion-label>
        </ion-card-header>
        <ion-card-content>
            <div style="padding: 8px 15px 15px">
                <template v-if="selectedProperty.rental_property.check_out_tasks.length > 0">
                    <ion-label>
                        Please complete the following checklist before departing. Thank you for staying with us!
                    </ion-label>
                    <ul style="padding-left: 25px">
                        <template
                            v-for="(task, index) in selectedProperty.rental_property.check_out_tasks"
                            :key="index"
                        >
                            <li>
                                {{ formatCategoryText(task.category) }}{{ task.description ? ':' : '' }}
                                {{ task.description }}
                            </li>
                        </template>
                    </ul>
                    <ion-button v-if="false" style="width: 100%; margin-top: 15px; --border-radius: 5px"
                        ><ion-icon :src="alertCircleOutline" />Checkout Checklist</ion-button
                    >
                </template>
                <template v-else>
                    <ion-label
                        >There are no check out tasks listed for this property. Thank you for staying with
                        us!</ion-label
                    >
                </template>
            </div>
        </ion-card-content>
    </ion-card>
</template>

<script lang="ts">
    import { defineComponent, computed } from 'vue';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';
    import { alertCircleOutline } from 'ionicons/icons';

    export default defineComponent({
        setup() {
            const store = useStore();

            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            const formatCategoryText = (text: string) => {
                return text
                    .replace(/_/g, ' ')
                    .toLowerCase()
                    .replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
            };

            return {
                selectedProperty,
                store,
                alertCircleOutline,
                formatCategoryText,
            };
        },
    });
</script>
