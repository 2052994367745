import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"padding":"8px 15px 15px"} }
const _hoisted_2 = { style: {"padding-left":"25px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, { class: "section-title" }, {
            default: _withCtx(() => [
              _createTextVNode("Check Out Tasks")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.selectedProperty.rental_property.check_out_tasks.length > 0)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Please complete the following checklist before departing. Thank you for staying with us! ")
                    ]),
                    _: 1
                  }),
                  _createElementVNode("ul", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedProperty.rental_property.check_out_tasks, (task, index) => {
                      return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(_ctx.formatCategoryText(task.category)) + _toDisplayString(task.description ? ':' : '') + " " + _toDisplayString(task.description), 1))
                    }), 128))
                  ]),
                  false
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        style: {"width":"100%","margin-top":"15px","--border-radius":"5px"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { src: _ctx.alertCircleOutline }, null, 8, ["src"]),
                          _createTextVNode("Checkout Checklist")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(), _createBlock(_component_ion_label, { key: 1 }, {
                  default: _withCtx(() => [
                    _createTextVNode("There are no check out tasks listed for this property. Thank you for staying with us!")
                  ]),
                  _: 1
                }))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}