<template>
    <modal-base-layout showPropertySegment title="During Your Stay">
        <div v-if="viewOption == 'listView' && selectedProperty.rental_property.during_stay">
            <template v-for="i in categories" :key="i">
                <div v-if="refreshItemView && filteredItems(i.sysname).size > 0">
                    <div
                        class="section-header"
                        style="
                            margin-top: 10px;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                        "
                    >
                        <ion-label class="section-title">{{ i.name_plural }}</ion-label>
                        <div
                            style="display: flex; align-items: center; cursor: pointer"
                            @click="duringStayListModal(i)"
                            v-if="filteredItems(i.sysname).size > 3"
                        >
                            <ion-label style="font-size: 15px; font-weight: bold">View All</ion-label>
                            <ion-icon :icon="chevronForward" style="font-size: 20px; padding-left: 5px"></ion-icon>
                        </div>
                    </div>
                    <swiper-container class="propertyDetails" :slides-per-view="'1.5'">
                        <template v-for="(item, index) in filteredItems(i.sysname).items" :key="index">
                            <swiper-slide @click="duringStayDetailModal(item)">
                                <ion-card style="min-height: 100px; margin: 15px; cursor: pointer">
                                    <img
                                        :src="item.image || 'assets/img/no_image.png'"
                                        style="width: 100%; height: 100px; object-fit: cover"
                                    />
                                    <ion-card-content style="margin: 5px">
                                        <ion-label style="font-weight: bold">{{ item.name }}</ion-label>
                                        <div style="display: flex; flex-direction: column">
                                            <ion-label style="font-size: 12px" v-if="item.cuisine">{{
                                                item.cuisine
                                            }}</ion-label>
                                            <ion-label style="font-size: 12px" v-if="item.sport_type">{{
                                                item.sport_type
                                            }}</ion-label>
                                            <ion-label
                                                style="font-size: 14px"
                                                v-if="item.address.address1 && item.address.short_name"
                                                >{{ item.address.address1 }}</ion-label
                                            >
                                            <ion-label
                                                style="font-size: 14px"
                                                v-if="item.address.address1 && item.address.short_name"
                                                >{{ item.address.short_name }}</ion-label
                                            >
                                        </div>
                                    </ion-card-content>
                                </ion-card>
                            </swiper-slide>
                        </template>
                    </swiper-container>
                </div>
            </template>
        </div>

        <div
            v-if="viewOption == 'mapView' && selectedProperty.rental_property.during_stay"
            style="width: 100%; height: 100vh"
        >
            <l-map
                ref="map"
                v-model:zoom="zoom"
                :center="[
                    selectedProperty.rental_property.address.latitude,
                    selectedProperty.rental_property.address.longitude,
                ]"
            >
                <l-tile-layer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    layer-type="base"
                    name="Lodgix Guest App"
                ></l-tile-layer>
                <l-marker
                    :lat-lng="[
                        selectedProperty.rental_property.address.latitude,
                        selectedProperty.rental_property.address.longitude,
                    ]"
                    ><l-popup>{{ selectedProperty.rental_property.name }}</l-popup></l-marker
                >

                <l-marker
                    v-for="(item, index) in selectedProperty.rental_property.during_stay.items"
                    :key="index"
                    :lat-lng="[item.address.latitude, item.address.longitude]"
                >
                    <l-popup
                        style="
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                        "
                        ><ion-label style="font-size: 15px">{{ item.name }}</ion-label>
                        <button class="button" @click="duringStayDetailModal(item)">View More</button>
                    </l-popup>
                </l-marker>
            </l-map>
        </div>

        <div
            v-if="
                !selectedProperty.rental_property.during_stay ||
                selectedProperty.rental_property.during_stay.items.length <= 0
            "
        >
            <div
                style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-top: 50px;
                "
            >
                <ion-label style="font-size: 20px; font-weight: bold; padding: 10px">No Items Available</ion-label>
                <ion-label style="font-size: 16px; padding: 10px"
                    >The owner has not provided anything in the local area for the current property.</ion-label
                >
            </div>
        </div>
        <div style="margin: 5px"></div>
    </modal-base-layout>

    <!--<ion-footer v-if="selectedProperty.rental_property.during_stay">
        <ion-toolbar>
            <ion-segment mode="ios" class="footer-segment" v-model="viewOption">
                <ion-segment-button value="listView">
                    <ion-label>List View</ion-label>
                </ion-segment-button>
                <ion-segment-button value="mapView">
                    <ion-label>Map View</ion-label>
                </ion-segment-button>
            </ion-segment>
        </ion-toolbar>
    </ion-footer>-->
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, ref, computed, watch } from 'vue';
    import { bicycle, closeOutline, home, restaurant, map, chevronForward } from 'ionicons/icons';
    import { useStore } from '@/store/store';
    import 'leaflet/dist/leaflet.css';
    import AppFunctions from '@/services/AppFunctions';
    import { duringStayDetailModal, duringStayListModal } from '@/services/ModalController';
    import { getDuringStayItemTodayHours } from '@/services/Date';
    import 'leaflet/dist/leaflet.css';
    import { LMap, LTileLayer, LMarker, LPopup, LIcon } from '@vue-leaflet/vue-leaflet';

    export default defineComponent({
        components: {
            LMap,
            LTileLayer,
            LMarker,
            LPopup,
        },
        props: {},
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();
            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });
            const refreshItemView = ref(true);
            const categories = selectedProperty.value.rental_property.during_stay?.categories || [];
            const viewOption = ref('listView');
            const zoom = ref(14);
            const iconSize = ref(64);

            const filteredItems = (category: string) => {
                if (selectedProperty.value.rental_property.during_stay) {
                    const items = selectedProperty.value.rental_property.during_stay.items.filter(
                        (item: any) => item.category == category
                    );

                    return {
                        items: items ? items.slice(0, 3) : [],
                        size: items ? items.length : 0,
                    };
                } else {
                    return {
                        items: [],
                        size: 0,
                    };
                }
            };

            const dynamicSize = computed(() => {
                return [iconSize.value, iconSize.value * 1.15];
            });

            const dynamicAnchor = computed(() => {
                return [iconSize.value / 2, iconSize.value * 1.15];
            });

            watch(
                () => store.selectedTechReservationId,
                () => {
                    refreshItemView.value = false;
                    setTimeout(function () {
                        refreshItemView.value = true;
                    }, 50);
                }
            );

            onMounted(() => {
                //
            });

            return {
                ionRouter,
                closeOutline,
                store,
                bicycle,
                chevronForward,
                restaurant,
                map,
                home,
                selectedProperty,
                refreshItemView,
                categories,
                viewOption,
                AppFunctions,
                zoom,
                iconSize,
                dynamicAnchor,
                dynamicSize,
                filteredItems,
                duringStayDetailModal,
                duringStayListModal,
                getDuringStayItemTodayHours,
            };
        },
    });
</script>

<style scoped>
    ion-card {
        margin: 2px;
    }
    ion-item {
        --padding-top: 5px;
        --padding-bottom: 5px;
    }

    ion-toolbar {
        padding: 0px !important;
    }

    .footer-segment {
        margin: 0px 5px;
        padding: 0px;
        width: 100%;
        border-radius: 10px;
    }

    .button {
        background-color: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast);
        border: none;
        padding: 7px 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px;
        cursor: pointer;
        border-radius: 5px;
    }
</style>
