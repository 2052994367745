import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c9e163d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"position":"relative","padding":"0px"} }
const _hoisted_2 = { style: {"font-weight":"700","position":"absolute","bottom":"0","left":"0","text-align":"left","padding":"15px 15px","width":"100%","height":"100%","background":"linear-gradient(to top, rgba(0, 0, 0, 0.75), transparent)","display":"flex","flex-direction":"column","justify-content":"flex-end"} }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_PropertySegment = _resolveComponent("PropertySegment")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, {
      mode: _ctx.showPropertySegment && _ctx.store.reservationData.tech_reservations.length > 1 ? '' : 'ios'
    }, {
      default: _withCtx(() => [
        (!_ctx.imageUrl && !_ctx.hideHeader)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_ion_toolbar, {
                mode: "ios",
                class: _normalizeClass(["custom-safe-area", 
                    _ctx.showPropertySegment && _ctx.store.reservationData.tech_reservations.length > 1 ? 'segment-header' : ''
                ])
              }, {
                default: _withCtx(() => [
                  (_ctx.showCloseButton && _ctx.Capacitor.getPlatform() == 'ios')
                    ? (_openBlock(), _createBlock(_component_ion_icon, {
                        key: 0,
                        slot: "start",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel())),
                        style: {"width":"30px","height":"30px","cursor":"pointer"},
                        src: _ctx.closeOutline
                      }, null, 8, ["src"]))
                    : _createCommentVNode("", true),
                  (_ctx.title)
                    ? (_openBlock(), _createBlock(_component_ion_title, {
                        key: 1,
                        style: {"font-weight":"700","font-size":"20px"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.title), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.showCloseButton && _ctx.Capacitor.getPlatform() != 'ios')
                    ? (_openBlock(), _createBlock(_component_ion_icon, {
                        key: 2,
                        slot: "end",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancel())),
                        style: {"width":"30px","height":"30px","cursor":"pointer"},
                        src: _ctx.closeOutline
                      }, null, 8, ["src"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["class"]),
              (_ctx.showPropertySegment && _ctx.store.reservationData.tech_reservations.length > 1)
                ? (_openBlock(), _createBlock(_component_PropertySegment, { key: 0 }))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["mode"]),
    (_ctx.$slots.header)
      ? (_openBlock(), _createBlock(_component_ion_header, {
          key: 0,
          style: {"box-shadow":"none","border":"none"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, { style: {"--background":"white","padding":"0px !important"} }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "header", {}, undefined, true)
              ]),
              _: 3
            })
          ]),
          _: 3
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ion_content, { mode: "ios" }, {
      default: _withCtx(() => [
        (_ctx.showCloseButton && _ctx.imageUrl)
          ? (_openBlock(), _createBlock(_component_ion_icon, {
              key: 0,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.cancel())),
              style: {"width":"30px","height":"30px","cursor":"pointer","position":"fixed","top":"env(safe-area-inset-top)","left":"env(safe-area-inset-left)","margin":"10px 0px 0px 15px","z-index":"1000 !important","background-color":"var(--ion-color-secondary)","border-radius":"50%","box-shadow":"0px 0px 10px 0px rgba(0, 0, 0, 0.5)"},
              src: _ctx.closeOutline,
              color: "dark"
            }, null, 8, ["src"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.noClass ? '' : 'content'),
          style: _normalizeStyle(_ctx.props.styleString)
        }, [
          (_ctx.imageUrl)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                style: _normalizeStyle([{"width":"100%","overflow":"hidden"}, `height: ${_ctx.imageHeight}px;`])
              }, [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_ion_label, { style: {"color":"white","font-size":"24px"} }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.title), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_label, { style: {"color":"white","font-size":"18px"} }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.subtitle), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("img", {
                    src: _ctx.imageUrl,
                    style: _normalizeStyle([{"width":"100%","object-fit":"cover"}, `height: ${_ctx.imageHeight}px;`])
                  }, null, 12, _hoisted_3)
                ])
              ], 4))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 6)
      ]),
      _: 3
    }),
    (_ctx.$slots.footer)
      ? (_openBlock(), _createBlock(_component_ion_footer, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, {
              style: _normalizeStyle([{ '--background': _ctx.footerBackground }, {"color":"black","padding":"0","padding-bottom":"env(safe-area-inset-bottom) !important"}])
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
              ]),
              _: 3
            }, 8, ["style"])
          ]),
          _: 3
        }))
      : _createCommentVNode("", true)
  ], 64))
}