<template>
    <div
        v-if="
            (selectedProperty.rental_property.address.latitude && selectedProperty.rental_property.address.longitude) ||
            selectedProperty.rental_property.address.address1 ||
            selectedProperty.rental_property.directions
        "
    >
        <ion-card>
            <ion-card-content>
                <div
                    v-if="
                        selectedProperty.rental_property.address.latitude &&
                        selectedProperty.rental_property.address.longitude &&
                        !hideMap
                    "
                    style="height: 350px; width: 100%"
                >
                    <div id="address-label">
                        <ion-label class="section-title">Address</ion-label>
                    </div>
                    <l-map
                        ref="mapRef"
                        v-model:zoom="zoom"
                        :center="[
                            selectedProperty.rental_property.address.latitude,
                            selectedProperty.rental_property.address.longitude,
                        ]"
                        @ready="handleMapReady"
                    >
                        <l-tile-layer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            layer-type="base"
                            name="Lodgix Guest App"
                        ></l-tile-layer>
                        <l-marker
                            :lat-lng="[
                                selectedProperty.rental_property.address.latitude,
                                selectedProperty.rental_property.address.longitude,
                            ]"
                        ></l-marker>
                    </l-map>
                </div>
                <div lines="none" style="margin: 15px" v-if="hideMap">
                    <ion-label class="section-title">Address</ion-label>
                </div>
                <ion-item lines="none" :style="[!hideMap ? '' : 'margin-top: -15px;']">
                    <ion-label text-wrap
                        >{{ selectedProperty.rental_property.address.address1 }} <br />
                        {{ selectedProperty.rental_property.address.short_name }}</ion-label
                    >
                    <ion-button
                        slot="end"
                        class="button-outline"
                        @click="
                            advancedActionSheet(
                                'Open with',
                                openInMaps(Capacitor.getPlatform()),
                                selectedProperty.rental_property.address.address1 +
                                    ' ' +
                                    selectedProperty.rental_property.address.short_name
                            )
                        "
                        >Open In Maps</ion-button
                    >
                </ion-item>
            </ion-card-content>
        </ion-card>
    </div>
</template>

<script lang="ts">
    import { defineComponent, computed, ref, onMounted, watch } from 'vue';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';
    import { Capacitor } from '@capacitor/core';
    import 'leaflet/dist/leaflet.css';
    import { LMap, LTileLayer, LMarker } from '@vue-leaflet/vue-leaflet';
    import { openInMaps } from '@/services/ActionSheetOptions';
    import { advancedActionSheet } from '@/services/ActionController';

    export default defineComponent({
        components: {
            LMap,
            LTileLayer,
            LMarker,
        },
        props: {
            hideMap: {
                type: Boolean,
                default: false,
            },
        },
        setup() {
            const store = useStore();
            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });
            const zoom = ref(15);
            const refreshItemView = ref(true);

            const mapRef = ref(null);

            onMounted(() => {
                setTimeout(function () {
                    window.dispatchEvent(new Event('resize'));
                }, 250);
            });

            watch(
                () => store.selectedTechReservationId,
                () => {
                    refreshItemView.value = false;
                    setTimeout(function () {
                        refreshItemView.value = true;
                    }, 50);
                }
            );

            const openAccordion = ref([]);

            const handleMapReady = (map: any) => {
                map.dragging.disable();
                map.touchZoom.disable();
                map.zoomControl.remove();
            };

            return {
                selectedProperty,
                store,
                Capacitor,
                zoom,
                mapRef,
                openAccordion,
                refreshItemView,
                openInMaps,
                handleMapReady,
                advancedActionSheet,
            };
        },
    });
</script>

<style scoped>
    #address-label {
        position: absolute;
        z-index: 1000;
        background-color: white;
        padding: 15px;
        border-radius: 0px 0px 5px 0px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    }
</style>
