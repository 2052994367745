import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d5f24ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { style: {"display":"flex","flex-direction":"column","padding-left":"10px"} }
const _hoisted_3 = { style: {"display":"flex","flex-direction":"column"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, {
    title: _ctx.props.category.name_plural
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems(_ctx.props.category.sysname).items, (i) => {
        return (_openBlock(), _createBlock(_component_ion_card, {
          key: i,
          style: {"margin":"15px","cursor":"pointer"},
          onClick: ($event: any) => (_ctx.duringStayDetailModal(i))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_content, { style: {"display":"flex","margin":"5px"} }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: i.image || 'assets/img/no_image.png',
                  style: {"height":"100px","object-fit":"cover","border-radius":"10px","aspect-ratio":"1/1"}
                }, null, 8, _hoisted_1),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_ion_label, { style: {"font-weight":"bold"} }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(i.name), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createElementVNode("div", _hoisted_3, [
                    (i.cuisine)
                      ? (_openBlock(), _createBlock(_component_ion_label, {
                          key: 0,
                          style: {"font-size":"14px"}
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(i.cuisine), 1)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true),
                    (i.sport_type)
                      ? (_openBlock(), _createBlock(_component_ion_label, {
                          key: 1,
                          style: {"font-size":"14px"}
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(i.sport_type), 1)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ion_label, { style: {"font-size":"14px"} }, {
                      default: _withCtx(() => [
                        _createTextVNode("Hours: " + _toDisplayString(_ctx.getDuringStayItemTodayHours(i)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["title"]))
}