import { useStore } from '@/store/store';
import API from './API';
import axios from 'axios';
import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';
import AppFunctions from './AppFunctions';

export default {
    async requestMagicLink(email: string, reservationId: string) {
        return await API().post(
            '/authentication/magic-link/',
            {
                email: email,
                reservation_id: reservationId,
            },
            {
                method: 'POST',
            }
        );
    },

    async generateTokens(magicLinkToken: string) {
        return await API().post(
            '/authentication/token/',
            {
                token: magicLinkToken,
            },
            {
                method: 'POST',
            }
        );
    },

    async refreshToken(refreshToken: string) {
        return await API().post(
            '/authentication/token/refresh/',
            {
                refresh: refreshToken,
            },
            {
                method: 'POST',
            }
        );
    },

    async getUser() {
        return await API().get('/api/user/', {
            method: 'GET',
        });
    },

    async getPushNotifications(showArchived = false) {
        return await API().post(
            '/api/push-notifications/get-notifications/',
            {
                is_archived: showArchived,
            },
            {
                method: 'POST',
            }
        );
    },

    async setReadPushNotification(notificationId: string) {
        const store = useStore();
        return await API().post(
            '/api/push-notifications/mark-read/',
            {
                notification_id: notificationId,
            },
            {
                method: 'POST',
            }
        );
    },
    async setPushNotificationOpened() {
        return await API().post(
            '/api/push-notifications/push-notification-opened/',
            {},
            {
                method: 'POST',
            }
        );
    },

    async setPushNotificationArchived(notificationId: string) {
        return await API().post(
            '/api/push-notifications/mark-archived/',
            {
                notification_id: notificationId,
            },
            {
                method: 'POST',
            }
        );
    },

    async getReservationData(reservationId: string) {
        return await API().get(`/api/reservation/${reservationId}/`, {
            method: 'GET',
        });
    },

    async getAllReservations() {
        return await API().get('/api/reservation/', {
            method: 'GET',
        });
    },

    //save push notification token/device
    async createDevice(name: string, registration_id: string, device_id: string, type: string, active: boolean) {
        return await API().post(
            '/api/device/',
            {
                name: name,
                registration_id: registration_id,
                device_id: device_id,
                type: type,
                active: active,
            },
            {
                method: 'POST',
            }
        );
    },
    async deactivateDevice(registration_id: string) {
        if (registration_id === '') {
            return {
                data: {},
            };
        }
        return await API().put(
            `/api/device/${registration_id}/`,
            {
                active: false,
            },
            {
                method: 'PUT',
            }
        );
    },
    async getDeviceById(registration_id: string) {
        if (registration_id === '') {
            return {
                data: {},
            };
        }
        return API().get('/api/device/' + registration_id + '/', {
            method: 'GET',
        });
    },

    async getPastReviews() {
        const store = useStore();
        if (store.selectedReservationId) {
            return await API().post(
                '/api/hosted-form/past-reviews/',
                {
                    meta_reservation: store.selectedReservationId,
                },
                {
                    method: 'POST',
                }
            );
        } else {
            return {
                data: [],
            };
        }
    },

    //hosted forms api
    async getHostedFormGuestReview(reservationId: string) {
        return await API().post(
            `/api/hosted-form/get-review/`,
            {
                meta_reservation: reservationId,
            },
            {
                method: 'POST',
            }
        );
    },
    async saveHostedForm(data: any, uuid: string) {
        const urlBase = process.env.VUE_APP_URL_BASE;
        const api = axios.create({
            baseURL: urlBase,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return await api.post(
            '/app/hosted-form/save-form-data/',
            {
                data: data,
                uuid: uuid,
                is_guest_app: true,
            },
            {
                method: 'POST',
            }
        );
    },
    async getHostedFormList(reservationId: string) {
        return await API().get(`/api/hosted-form?meta_reservation=${reservationId}`, {
            method: 'GET',
        });
    },

    async getRentalAgreement() {
        const store = useStore();
        return await API().get(`/api/reservation/${store.selectedReservationId}/rental-agreement/`, {
            method: 'GET',
        });
    },

    async logStatistics() {
        if (Capacitor.getPlatform() === 'web') {
            return {
                data: {},
            };
        }

        const store = useStore();
        const deviceInfo = await Device.getInfo();
        const deviceId = (await Device.getId()).identifier;
        const isPushNotificationsEnabled = await AppFunctions.checkPushNotificationPermission();

        const response = await API()
            .post(
                `/api/reservation/${store.selectedReservationId}/log-statistics/`,
                {
                    device_platform: Capacitor.getPlatform(),
                    enabled_push_notifications: isPushNotificationsEnabled,
                    app_version: process.env.VUE_APP_VERSION,
                    device_identifier: deviceId,
                },
                {
                    method: 'POST',
                }
            )
            .catch((error) => {
                console.error('Error logging statistics', error);
            });

        return response;
    },

    async getGuestVerificationLink() {
        const store = useStore();
        return await API().get(`/api/verification/${store.selectedReservationId}/verify/`, {
            method: 'GET',
        });
    },

    async getConversations() {
        const store = useStore();
        return await API().get(`/api/chat/conversation/`, {
            method: 'GET',
        });
    },

    async getSharedUsers() {
        const store = useStore();
        return await API().get(`/api/reservation/${store.selectedReservationId}/get-shared-users/`, {
            method: 'GET',
        });
    },

    async updateSharedUsers(id: number, name: string, email: string) {
        const store = useStore();
        return await API().patch(
            `/api/reservation/${store.selectedReservationId}/update-shared-users/`,
            {
                id: id,
                name: name,
                email: email,
            },
            {
                method: 'PATCH',
            }
        );
    },

    async deleteSharedUser(id: number) {
        const store = useStore();
        return await API().post(
            `/api/reservation/${store.selectedReservationId}/delete-shared-users/`,
            {
                id: id,
            },
            {
                method: 'POST',
            }
        );
    },

    async createSharedUser(name: string, email: string) {
        const store = useStore();
        return await API().post(
            `/api/reservation/${store.selectedReservationId}/create-shared-users/`,
            {
                name: name,
                email: email,
            },
            {
                method: 'POST',
            }
        );
    },

    async sendSharedUserLogin(id: number, optionalMessage: string) {
        const store = useStore();
        return await API().post(
            `/api/reservation/${store.selectedReservationId}/send-shared-user-login/`,
            {
                id: id,
                optional_message: optionalMessage,
            },
            {
                method: 'POST',
            }
        );
    },

    async getSharedUserLogin(id: number) {
        const store = useStore();
        return await API().post(
            `/api/reservation/${store.selectedReservationId}/get-shared-user-login/`,
            {
                id: id,
            },
            {
                method: 'POST',
            }
        );
    },
};
