import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Capacitor } from '@capacitor/core';

export async function initialize() {
    if (Capacitor.getPlatform() === 'web') {
        await FirebaseAnalytics.initializeFirebase({
            apiKey: process.env.VUE_APP_API_KEY,
            authDomain: process.env.VUE_APP_AUTH_DOMAIN,
            projectId: process.env.VUE_APP_PROJECT_ID,
            storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
            messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
            appId: process.env.VUE_APP_APP_ID,
            measurementId: process.env.VUE_APP_MEASUREMENT_ID,
        });
    }
    await FirebaseAnalytics.setCollectionEnabled({
        enabled: true,
    });

    await FirebaseAnalytics.logEvent({
        name: 'initalized_app',
        params: {
            platform: Capacitor.getPlatform(),
        },
    });
}

export async function logEvent(eventName: string) {
    /*await FirebaseAnalytics.logEvent({
        name: 'page',
        params: {
            page_name: eventName,
        },
    });*/
}
