import { useStore } from '@/store/store';
import { logoApple, logoGoogle } from 'ionicons/icons';

export const openInMaps = (os: string) => {
    if (os == 'ios') {
        return [
            {
                text: 'Google Maps',
                icon: logoGoogle,
                handler: () => {
                    const store = useStore();
                    const encodedAddress = encodeURIComponent(store.openInMapsAddress);
                    window.open(`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`, '_blank');
                },
                data: {
                    action: 'share',
                },
            },
            {
                text: 'Apple Maps',
                icon: logoApple,
                handler: () => {
                    const store = useStore();
                    const encodedAddress = encodeURIComponent(store.openInMapsAddress);
                    window.open(`http://maps.apple.com/?daddr=${encodedAddress}`, '_blank');
                },
                data: {
                    action: 'share',
                },
            },
            {
                text: 'Cancel',
                role: 'cancel',
                data: {
                    action: 'cancel',
                },
            },
        ];
    } else {
        return [
            {
                text: 'Google Maps',
                icon: logoGoogle,
                handler: () => {
                    const store = useStore();
                    const encodedAddress = encodeURIComponent(store.openInMapsAddress);
                    window.open(`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`, '_blank');
                },
                data: {
                    action: 'share',
                },
            },
            {
                text: 'Cancel',
                role: 'cancel',
                data: {
                    action: 'cancel',
                },
            },
        ];
    }
};
