<template>
    <swiper-container
        :slides-per-view="1"
        :centered-slides="true"
        :navigation="props.showNavigation"
        :pagination="props.showPagination"
        :loop="true"
        effect="fade"
        :autoplay="props.autoPlay"
        :speed="700"
        style="border-radius: 10px !important"
    >
        <template v-if="!isSingleProperty">
            <template v-for="(item, index) in store.reservationData?.tech_reservations" :key="index">
                <swiper-slide style="border-radius: 10px !important">
                    <ion-img
                        v-if="item.rental_property?.photos[0]?.image"
                        :src="item.rental_property?.photos[0]?.image"
                        style="border-radius: 10px !important"
                    />
                    <ion-img v-else src="assets/img/no_image.png" style="border-radius: 10px !important" />

                    <div class="label-container" v-if="item.rental_property.name">
                        <div style="display: flex; flex-direction: column">
                            <label style="font-size: larger; font-weight: 700">{{ item.rental_property.name }}</label>
                            <label
                                v-if="item.rental_property.address.address1 && item.rental_property.address.short_name"
                                >{{
                                    item.rental_property.address.address1 +
                                    ' ' +
                                    item.rental_property.address.short_name
                                }}</label
                            >
                        </div>
                        <div style="display: flex; flex-direction: row; justify-content: end; align-items: center">
                            <div v-if="item.rental_property.virtual_tours_code">
                                <ion-button @click="virtualTourModal">
                                    <img src="assets/icons/video-tour.svg" style="width: 34px; height: 22px" />
                                </ion-button>
                            </div>
                            <div v-if="item.rental_property.video_code">
                                <ion-button @click="AppFunctions.openLink(item.rental_property.video_code)">
                                    <ion-icon :icon="videocam" />
                                </ion-button>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </template>
        </template>

        <template v-else>
            <template v-for="(item, index) in selectedProperty.rental_property.photos" :key="index">
                <swiper-slide style="border-radius: 10px !important">
                    <ion-img v-if="item.image" :src="item.image" style="border-radius: 10px !important" />
                    <ion-img v-else src="assets/img/no_image.png" style="border-radius: 10px !important" />

                    <div class="label-container" v-if="selectedProperty.rental_property.name">
                        <div style="display: flex; flex-direction: column">
                            <label style="font-size: larger; font-weight: 700">{{
                                selectedProperty.rental_property.name
                            }}</label>
                            <label v-if="selectedProperty.rental_property.type">{{
                                selectedProperty.rental_property.type
                            }}</label>
                        </div>
                        <div style="display: flex; flex-direction: row; justify-content: end; align-items: center">
                            <div v-if="selectedProperty.rental_property.virtual_tours_code">
                                <ion-button @click="virtualTourModal">
                                    <img src="assets/icons/video-tour.svg" style="width: 34px; height: 22px" />
                                </ion-button>
                            </div>
                            <div v-if="selectedProperty.rental_property.video_code">
                                <ion-button @click="AppFunctions.openLink(selectedProperty.rental_property.video_code)">
                                    <ion-icon :icon="videocam" />
                                </ion-button>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </template>
            <template v-if="selectedProperty.rental_property.photos.length <= 0">
                <swiper-slide style="border-radius: 10px !important">
                    <ion-img src="assets/img/no_image.png" style="border-radius: 10px !important" />
                    <div class="label-container" v-if="selectedProperty.rental_property.name">
                        <div style="display: flex; flex-direction: column">
                            <label style="font-size: larger; font-weight: 700">{{
                                selectedProperty.rental_property.name
                            }}</label>
                            <label v-if="selectedProperty.rental_property.type">{{
                                selectedProperty.rental_property.type
                            }}</label>
                        </div>
                        <div style="display: flex; flex-direction: row; justify-content: end; align-items: center">
                            <div v-if="selectedProperty.rental_property.virtual_tours_code">
                                <ion-button @click="virtualTourModal">
                                    <img src="assets/icons/video-tour.svg" style="width: 34px; height: 22px" />
                                </ion-button>
                            </div>
                            <div v-if="selectedProperty.rental_property.video_code">
                                <ion-button @click="AppFunctions.openLink(selectedProperty.rental_property.video_code)">
                                    <ion-icon :icon="videocam" />
                                </ion-button>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </template>
        </template>
    </swiper-container>
</template>

<script lang="ts">
    import { useIonRouter } from '@ionic/vue';
    import { computed, defineComponent, onMounted, ref } from 'vue';
    import { useStore } from '@/store/store';
    import { refreshCircle, videocam } from 'ionicons/icons';
    import { virtualTourModal } from '@/services/ModalController';
    import AppFunctions from '@/services/AppFunctions';

    export default defineComponent({
        components: {
            //
        },
        props: {
            showPagination: {
                type: Boolean,
                default: false,
            },
            showNavigation: {
                type: Boolean,
                default: false,
            },
            autoPlay: {
                type: Boolean,
                default: false,
            },
            isSingleProperty: {
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            const ionRouter = useIonRouter();
            const store = useStore();
            const showThumbnail = ref(true);

            const isMultipleProperties = computed(() => {
                if (!store.reservationData) {
                    return false;
                }
                return store.reservationData?.tech_reservations?.length > 1;
            });

            const selectedProperty = computed(() => {
                const property = store.reservationData?.tech_reservations?.find(
                    (x: any) => x.id === store.selectedTechReservationId
                );
                return property;
            });

            const loadFullImage = () => {
                console.log('loadFullImage');
                showThumbnail.value = false;
            };

            return {
                ionRouter,
                store,
                refreshCircle,
                isMultipleProperties,
                selectedProperty,
                props,
                showThumbnail,
                AppFunctions,
                videocam,
                virtualTourModal,
                loadFullImage,
            };
        },
    });
</script>

<style scoped>
    ion-img {
        width: 100%;
        aspect-ratio: 16/16; /* 16:10 */
        object-fit: cover;
    }
    .label-container {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 15px;
        padding-bottom: 25px;
        padding-top: 40%;
        color: #fff;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.75), transparent);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        width: 100%;
        font-size: large;
    }
    .button-container {
        position: absolute;
        top: 0;
        right: 0;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 0px 0px 0px 5px;
        padding-bottom: 5px;
        padding-left: 5px;
        display: flex;
        flex-direction: row;
        text-align: right;
        justify-content: flex-end;
        font-size: large;
    }
</style>
